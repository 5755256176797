import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { DISMISSED_PAGE_DELETE } from '../../util/constants';
import { ViiisionDeleteDialog } from '../ViiisionDeleteDialog';
import { bindActionCreators } from 'redux';
import { deleteTopic } from '../../store/slices/topicSlice';

export type PageDeleteDialogProps = {
	open: boolean;
	topicId: string;
	handleClose: () => void;
};

export const PageDeleteDialog = (props: PageDeleteDialogProps) => {
	const { open, topicId, handleClose } = { ...props };
	const dispatch = useAppDispatch();
	const deletePage = bindActionCreators(deleteTopic, dispatch);

	const handleDelete = () => {
		deletePage(topicId);
	};

	return (
		<ViiisionDeleteDialog
			id={DISMISSED_PAGE_DELETE}
			open={open}
			title="Delete Page?"
			handleClose={handleClose}
			handleDelete={handleDelete}
			firstLine="Deleting this page will remove it permanently."
			secondLine="Are you sure you want to delete this page?"
		/>
	);
};
