import { createStyles, makeStyles, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { Status, toString } from '../../store/models/status/Status';
import { addTip } from '../../store/slices/editorSlice';
import { topicSetStatus } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { PAGE_HIDDEN_TIP, PAGE_INCOGNITO_TIP, PAGE_TEMPORARY_TIP } from '../../util/constants';
import { ViiisionTooltip } from '../ViiisionTooltip';
import { IncognitoIcon } from '../SvgComponents/SvgIncognito';
import { TemporaryIcon } from '../SvgComponents/SvgTemporary';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: colors.grayLight,
			'&:hover': {
				color: colors.blueAccent,
			},
		},
		button: {
			marginLeft: 10,
			'&:hover': {
				backgroundColor: colors.blueAccent20,
			},
		},
	})
);

export type ViiisionPageStateToggleProps = {
	topicId: string;
	status: Status;
	// topicSetStatus: (payload: { status: Status; topicId: string }) => void;
	// visibleTopicOptions: (payload: { status: Status; topicId: string }) => void;
	// Live   = 1
	// Hidden = 2
	// Secret = 3
	// Temporary = 4
};

const ViiisionPageStateToggle = (props: ViiisionPageStateToggleProps) => {
	const { topicId, status } = { ...props };
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const setStatus = bindActionCreators(topicSetStatus, dispatch);
	const addTheTip = bindActionCreators(addTip, dispatch);

	const handleClick = (e) => {
		// If status is the last (4), next status is 1, otherwise increment by 1
		let newStatus = status === 4 ? 1 : status + 1;

		if (newStatus === Status.Hidden) {
			addTheTip(PAGE_HIDDEN_TIP);
		} else if (newStatus === Status.Secret) {
			addTheTip(PAGE_INCOGNITO_TIP);
		} else if (newStatus === Status.Temporary) {
			addTheTip(PAGE_TEMPORARY_TIP);
		}
		setStatus({ topicId, status: newStatus });
	};

	const toggleIcon = (status) => {
		switch (status) {
			case Status.Live:
				return <VisibilityIcon fontSize="small" className={classes.icon} />;
			// break; //unreachable code
			case Status.Hidden:
				return <VisibilityOffIcon fontSize="small" className={classes.icon} />;
			// break;
			case Status.Secret:
				return <IncognitoIcon fontSize="medium" className={classes.icon} />;
			// break;
			case Status.Temporary:
				return <TemporaryIcon fontSize="medium" className={classes.icon} />;
			// break;
			// default
		}
	};

	return (
		<ViiisionTooltip message={toString(status)} placement="right">
			<IconButton className={classes.button} onClick={handleClick} size="small">
				{toggleIcon(status)}
			</IconButton>
		</ViiisionTooltip>
	);
};

export default ViiisionPageStateToggle;
