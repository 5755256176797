import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Theme } from '@material-ui/core/styles/createTheme';
import Grid from '@material-ui/core/Grid';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { CardTitle } from './CardComponents/CardTitle';
import { FlatButton } from '../Buttons/FlatButton';
import { InviteEditor } from './InviteEditor/InviteEditor';
import { useAppSelector } from '../../store/hooks';
import { _organization } from '../../store/slices/organizationSlice';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formCard: {
			marginBottom: 20,
			width: 352,
			height: 170,
			backgroundColor: theme.palette.background.paper,
		},
		titleBox: {
			display: 'flex',
			alignItems: 'center',
		},
		actionsContainer: {
			display: 'flex',
			flexDirection: 'row-reverse',
			textAlign: 'right',
		},
		formActionButton: {
			height: 32,
			width: 150,
			marginRight: 30,
		},
	})
);

export const InviteCard = () => {
	const [openEditor, setOpenEditor] = useState(false);
	const classes = useStyles();
	const organization = useAppSelector(_organization);

	return (
		<Card variant="outlined" className={classes.formCard}>
			<CardContent>
				<CardTitle title="Group Members" />
				<Typography variant="body2" paragraph>
					Invite group members via email to build your Viiision community.
				</Typography>
				<Grid container spacing={2} className={classes.actionsContainer}>
					<Grid item xs={6}>
						<FlatButton
							variant="contained"
							onClick={() => setOpenEditor(true)}
							color="secondary"
							className={classes.formActionButton}
						>
							Invite Members
						</FlatButton>
						<InviteEditor
							open={openEditor}
							setOpen={(open: boolean) => setOpenEditor(open)}
							organization={organization}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
