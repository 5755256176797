import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { colors } from '../../theme/palette';

const LeftArrow = (placement?: number): CSSProperties => ({
	content: '""',
	position: 'absolute',
	top: `-webkit-calc(${placement ?? 50}%)`,
	right: '-webkit-calc(100% - 10px)',
	height: '15px',
	width: `15px`,
	background: colors.pureWhite,
	transform: 'rotate(45deg)',
	borderBottom: 'inherit',
	borderRight: 'inherit',
});

const RightArrow = (placement?: number): CSSProperties => ({
	content: '""',
	position: 'absolute',
	top: `-webkit-calc(${placement ?? 50}%)`,
	left: '-webkit-calc(100% - 10px)',
	height: '15px',
	width: `15px`,
	background: colors.pureWhite,
	transform: 'rotate(45deg)',
	borderBottom: 'inherit',
	borderRight: 'inherit',
});

const TopArrow = (placement?: number): CSSProperties => ({
	content: '""',
	position: 'absolute',
	bottom: '-webkit-calc(100% - 10px)',
	left: `-webkit-calc(${placement ?? 50}%)`,
	height: '15px',
	width: `15px`,
	background: colors.pureWhite,
	transform: 'rotate(45deg)',
	borderBottom: 'inherit',
	borderRight: 'inherit',
});

const BottomArrow = (placement?: number): CSSProperties => ({
	content: '""',
	position: 'absolute',
	top: '-webkit-calc(100% - 10px)',
	left: `-webkit-calc(${placement ?? 50}%)`,
	height: '15px',
	width: `15px`,
	background: colors.pureWhite,
	transform: 'rotate(45deg)',
	borderBottom: 'inherit',
	borderRight: 'inherit',
});

const Arrow = (position: 'left' | 'right' | 'top' | 'bottom', placement?: number) => {
	let arrowStyle: CSSProperties;
	switch (position) {
		case 'top':
			arrowStyle = TopArrow(placement);
			break;
		case 'bottom':
			arrowStyle = BottomArrow(placement);
			break;
		case 'right':
			arrowStyle = RightArrow(placement);
			break;
		case 'left':
			arrowStyle = LeftArrow(placement);
			break;
	}
	return arrowStyle;
};

export type ViiisionWalkthroughArrowProps = {
	position: 'left' | 'right' | 'top' | 'bottom';
	placement?: number;
};

export const ViiisionWalkthroughArrow = (props: ViiisionWalkthroughArrowProps) => {
	const { position, placement } = { ...props };
	return <div style={Arrow(position, placement)} />;
};
