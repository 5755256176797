import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Content, FileLanguage, FileType } from '../../store/models';
import {
	_activeContentIds,
	_contents,
	_selectedContentId,
	contentChanged,
	contentDelete,
	contentReorder,
} from '../../store/slices/contentSlice';
import {
	_activeLanguageCode,
	_focusedItemName,
	_mlOpen,
	linkDialogMenuChange,
	toggleMlDrawer,
} from '../../store/slices/editorSlice';
import { _selectedLocation, changeHeaderLogo } from '../../store/slices/locationSlice';
import {
	_selectedTopic,
	changeBackground,
	topicContentDelete,
	topicContentReorder,
} from '../../store/slices/topicSlice';
import { HEADER_LOGO, SETTINGS_MENU, SOCIAL_SHARING, TOPIC_BACKGROUND } from '../../util/constants';
import { BannerAndLogoMenu } from '../SettingsMenuOptions/BannerAndLogoMenu';
import { ButtonMenu } from '../SettingsMenuOptions/ButtonMenu';
import { ImageBtnMenu } from '../SettingsMenuOptions/ImageBtnMenu';
import { ImageMenu } from '../SettingsMenuOptions/ImageMenu';
import { OptionGroup } from '../SettingsMenuOptions/OptionGroup';
import { SettingsMenu } from '../SettingsMenuOptions/SettingsMenu';
import { SubActions } from '../SettingsMenuOptions/SubActions';
import { TextMenu } from '../SettingsMenuOptions/TextMenu';
import { VideoMenu } from '../SettingsMenuOptions/VideoMenu';
import { ViiisionDrawer } from './ViiisionDrawer';
import PageMetadata from '../PageMetadata/PageMetadata';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerBox: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		contentBox: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
	})
);

export type RightDrawerProps = {
	isExpanded?: boolean;
	component?: React.ReactNode;
	content?: Content;
};

const defaultProps = {
	isExpanded: true,
};

export const RightDrawer = (props: RightDrawerProps) => {
	const { isExpanded, component, content } = { ...defaultProps, ...props };
	const classes = useStyles();
	const focusedItemName = useAppSelector(_focusedItemName);

	return (
		<ViiisionDrawer anchor="right" isExpanded={isExpanded}>
			<Box data-tour="right-drawer" className={classes.drawerBox}>
				<Box className={classes.contentBox}>
					{content && content.type === 'text' ? (
						<OptionGroup title="Text"></OptionGroup>
					) : focusedItemName === SOCIAL_SHARING ? (
						<OptionGroup title="Page Metadata"></OptionGroup>
					) : (
						<></>
					)}
					<div id="tinyToolbarSpot" style={{ display: content?.type === 'text' ? 'block' : 'none' }} />
					{component}
				</Box>
			</Box>
		</ViiisionDrawer>
	);
};

const RightDrawerContainer = (props: Omit<RightDrawerProps, 'component'>) => {
	const contents = useAppSelector(_contents);
	const contentId = useAppSelector(_selectedContentId);
	const content = contents[contentId];
	const focusedItemName = useAppSelector(_focusedItemName);
	const activeContents = useAppSelector(_activeContentIds);
	const selectedTopic = useAppSelector(_selectedTopic);
	const selectedLocation = useAppSelector(_selectedLocation);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const openMl = useAppSelector(_mlOpen);
	const length = activeContents.length;
	const index = activeContents.findIndex((id) => id === contentId);
	const [translation, setTranslation] = useState<FileLanguage>();

	//this is here to make sure we get a refresh
	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));
	}, [selectedTopic, content, focusedItemName, activeLanguageCode]);

	let component: React.ReactNode;
	const dispatch = useAppDispatch();

	const setBackgroundCropImage = (image, fileName) => {
		actions.onBackgroundChange({ name: fileName, url: image });
	};

	const setLogoCropImage = (image, fileName) => {
		actions.changeHeaderLogo({ name: fileName, url: image });
	};

	const setContentCropImage = (image, fileName) => {
		actions.onChangeContent({ language: translation?.language, fileUrl: image, fileName: fileName, id: contentId });
	};

	const actions = bindActionCreators(
		{
			onChangeContent: contentChanged,
			onReorderContent: contentReorder,
			onReorderTopicContent: topicContentReorder,
			onDeleteContent: contentDelete,
			onDeleteTopicContent: topicContentDelete,
			onBackgroundChange: changeBackground,
			onMenuChange: linkDialogMenuChange,
			toggleMlDrawer,
			changeHeaderLogo,
		},
		dispatch
	);

	const preparedSubActions = index >= 0 && (
		<SubActions
			multilanguage={selectedLocation.supportedLanguages.length > 1}
			length={length}
			index={index}
			type={content?.type}
			handleReorder={(payload) => {
				actions.onReorderContent(payload);
				actions.onReorderTopicContent(payload);
			}}
			onDeleteContent={(id) => {
				actions.onDeleteContent(id);
				actions.onDeleteTopicContent(id);
			}}
		/>
	);

	const imageBtnMenu = (
		<>
			<ImageBtnMenu
				languageCode={translation?.language}
				name={translation?.fileName}
				imageUrl={translation?.fileUrl}
				toCropData={setContentCropImage}
				setImagePreview={(file: File) => {
					actions.onChangeContent({
						language: translation?.language,
						fileUrl: URL.createObjectURL(file),
						fileName: `${translation?.language}_${file.name}`,
						id: contentId,
					});
				}}
				title={translation?.title}
				url={translation?.url}
				onChange={(title, url) => {
					actions.onChangeContent({
						language: translation?.language,
						title: title,
						url: url,
						id: contentId,
					});
				}}
				onMenuChange={actions.onMenuChange}
			>
				{preparedSubActions}
			</ImageBtnMenu>
		</>
	);

	if (focusedItemName === HEADER_LOGO) {
		component = (
			<BannerAndLogoMenu
				name="Logo"
				imageUrl={selectedLocation.headerLogo}
				toCropData={setLogoCropImage}
				setImagePreview={(file) => {
					actions.changeHeaderLogo({
						name: file.name,
						url: URL.createObjectURL(file),
					});
				}}
			/>
		);
	}

	if (focusedItemName === TOPIC_BACKGROUND) {
		component = (
			<BannerAndLogoMenu
				name="Page Background"
				imageUrl={selectedTopic?.exhibitImage}
				toCropData={setBackgroundCropImage}
				setImagePreview={(file) => {
					actions.onBackgroundChange({ name: file.name, url: URL.createObjectURL(file) });
				}}
			/>
		);
	}

	if (focusedItemName === SETTINGS_MENU) {
		component = <SettingsMenu open={openMl} setOpen={actions.toggleMlDrawer} />;
	}

	if (focusedItemName === SOCIAL_SHARING) {
		component = <PageMetadata />;
	} else if (contentId && content) {
		switch (contentId && content && content.type) {
			case FileType.text:
				component = (
					<>
						<TextMenu>{preparedSubActions}</TextMenu>
					</>
				);
				break;
			case FileType.image:
				component = (
					<>
						<ImageMenu
							languageCode={translation?.language}
							name={translation?.fileName}
							imageUrl={translation?.fileUrl}
							toCropData={setContentCropImage}
							setImagePreview={(file: File) => {
								actions.onChangeContent({
									language: translation?.language,
									fileUrl: URL.createObjectURL(file),
									fileName: `${translation?.language}_${file.name}`,
									id: contentId,
								});
							}}
						>
							{preparedSubActions}
						</ImageMenu>
					</>
				);
				break;
			case FileType.imageBtn: // This type is placed on new image button content. You'll never see this type come in from the db.
				component = imageBtnMenu;
				break;
			case FileType.webview:
				if (translation?.fileUrl) {
					// if there is a fileUrl, then it is an image button
					component = imageBtnMenu;
				} else {
					component = (
						<>
							<ButtonMenu
								title={translation?.title}
								url={translation?.url}
								onChange={(title, url) => {
									actions.onChangeContent({
										language: translation?.language,
										title: title,
										url: url,
										id: contentId,
									});
								}}
								onMenuChange={actions.onMenuChange}
							>
								{preparedSubActions}
							</ButtonMenu>
						</>
					);
				}
				break;
			case FileType.video:
				const video = translation?.fileUrl.includes('blob') ? translation?.fileUrl : translation?.content;
				component = (
					<>
						<VideoMenu
							languageCode={translation?.language}
							name={translation?.fileName}
							videoUrl={video}
							setVideo={(file) => {
								actions.onChangeContent({
									language: translation?.language,
									fileUrl: URL.createObjectURL(file),
									fileName: `${translation?.language}_${file.name}`,
									id: contentId,
								});
							}}
						>
							{preparedSubActions}
						</VideoMenu>
					</>
				);
				break;
		}
	}

	const state = { component, content };

	return <RightDrawer {...props} {...state} {...actions} />;
};

export default RightDrawerContainer;
