import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import 'date-fns';
import { useState } from 'react';
import { createInvitation } from '../../../store/api-client';
import { useAppSelector } from '../../../store/hooks';
import { Invitation } from '../../../store/models/invitation/Invitation';
import { _selectedLocationId } from '../../../store/slices/locationSlice';
import { colors } from '../../../theme/palette';
import { DO_NOT_SHOW_SPONSORED_INVITE_DIALOG, MAIL_VALIDATION_REGEX } from '../../../util/constants';
import { FlatButton } from '../../Buttons/FlatButton';
import { ViiisionFailureSnackbar } from '../../ViiisionFailureSnackbar';
import { ViiisionSuccessSnackbar } from '../../ViiisionSuccessSnackbar';
import { ViiisionTempDrawer } from '../../ViiisionTempDrawer';
import { EmailInputField } from './EmailInputField';
import { SponsorDialog } from './SponsorDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		editorBox: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			width: 547,
			height: '100%',
			marginLeft: 10,
			marginRight: 10,
		},
		desc: {
			width: '100%',
			padding: 5,
			marginBottom: 20,
		},
		title: {
			width: '100%',
			fontSize: 14,
			fontWeight: 'bold',
			textTransform: 'uppercase',
		},
		menu: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			left: 0,
			top: 0,
		},
		box: {
			display: 'flex',
			flexDirection: 'column',
			padding: 5,
		},
		actionBox: {
			marginTop: 20,
		},
		formActionButton: {
			height: 40,
			marginRight: 30,
		},
		actionsContainer: {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
		},

		radioGroup: {
			display: 'flex',
			width: '100%',
			borderSpacing: 10,
			marginTop: 10,
			marginBottom: 10,
		},
		radioField: {
			width: '50%',
			padding: 10,
			border: '1px solid #92A0AC',
			borderRadius: 10,
		},
		help: {
			color: colors.blueDark,
			marginBottom: 20,
			marginLeft: 20,
			cursor: 'pointer',
		},
		container: {
			marginBottom: 40,
		},
		commentText: {
			fontSize: 12,
		},
		radio: {
			'&.Mui-checked': {
				color: '#4B8DF8',
			},
		},
		checked: {},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 80,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #92A0AC',
			marginLeft: -10,
			marginRight: -10,
			marginTop: 40,
		},
	})
);

export type InviteEditorProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	organization: any;
};

export const InviteEditor = (props: InviteEditorProps) => {
	const { open, setOpen, organization } = {
		...props,
	};
	const classes = useStyles();
	//   const [sponsored, setSponsored] = useState(false);
	const sponsored = false;
	const [emailList, setEmailList] = useState([]);
	const [vEmailLength, setVEmailLength] = useState(0); // valid emails count in emali List
	const [openDialog, setOpenDialog] = useState(false);
	const [inviteSuccess, setInviteSuccess] = useState(false);
	const [inviteFailure, setInviteFailure] = useState(false);

	const selectedLocationId = useAppSelector(_selectedLocationId);

	const sendEmail = async () => {
		let emails = [];
		let invitationInfo: Invitation = {
			groupLeadId: '',
			groupLeaderEmail: '',
			locationId: '',
			memberEmailList: [''],
			orgName: '',
			planType: '',
			billing: '',
			sponsored: false,
			accepted: false,
			expirationAt: new Date(),
			route: '',
			coupon: '',
		};

		for (let i = 0; i < emailList.length; i++) {
			if (emailList[i].email.match(MAIL_VALIDATION_REGEX)) {
				emails.push(emailList[i].email);
			}
		}

		invitationInfo.groupLeadId = organization.id;
		invitationInfo.locationId = selectedLocationId;
		invitationInfo.groupLeaderEmail = organization.email;
		invitationInfo.planType = 'Explore';
		invitationInfo.billing = 'monthly';
		invitationInfo.sponsored = sponsored;
		invitationInfo.orgName = organization.name;
		invitationInfo.route = localStorage.getItem('route');
		invitationInfo.coupon = organization.coupon; // for groupLeads, added manually in DB

		let today = new Date();
		let expiryDate = new Date(today.getTime() + 60 * 24 * 60 * 60 * 1000);
		invitationInfo.expirationAt = expiryDate;
		invitationInfo.memberEmailList = emails;
		let sendResult = await createInvitation(invitationInfo);
		if (sendResult === 'Success') {
			setInviteSuccess(true);
			setInviteFailure(false);
		} else {
			setInviteFailure(true);
			setInviteSuccess(false);
		}

		setEmailList([]);
		setVEmailLength(0);
	};

	const handleOpen = () => {
		if (!localStorage.getItem(DO_NOT_SHOW_SPONSORED_INVITE_DIALOG) && sponsored) {
			setOpenDialog(true);
		} else {
			sendEmail();
		}
	};

	const handleClose = () => {
		setEmailList([]);
		setVEmailLength(0);
		setOpen(false);
		setOpenDialog(false);
	};

	const onEmailAdd = (emailItem: any) => {
		if (emailItem.email.match(MAIL_VALIDATION_REGEX)) {
			setVEmailLength(vEmailLength + 1);
		}
		let tempList = emailList;
		for (let i = 0; i < tempList.length; i++) {
			tempList[i].selected = false;
		}
		tempList.push(emailItem);
		setEmailList(tempList);
	};

	const onCancel = () => {
		let tempList = emailList;
		for (let i = 0; i < tempList.length; i++) {
			if (tempList[i].selected === true) {
				if (tempList[i].email.match(MAIL_VALIDATION_REGEX)) {
					setVEmailLength(vEmailLength - 1);
				}
				if (tempList.length > 1) {
					i === 0 ? (tempList[1].selected = true) : (tempList[i - 1].selected = true);
				}
				tempList.splice(i, 1);
			}
		}
		setEmailList(tempList);
	};

	const onSelect = (id: number) => {
		let tempList = emailList;
		for (let i = 0; i < tempList.length; i++) {
			id === i ? (tempList[i].selected = true) : (tempList[i].selected = false);
		}
		setEmailList(tempList);
	};

	return (
		<ViiisionTempDrawer title="Invite Members" open={open} setOpen={setOpen} onClose={handleClose}>
			<SponsorDialog
				open={openDialog}
				onSure={sendEmail}
				onClose={() => {
					setOpenDialog(false);
				}}
			/>
			<Box className={classes.editorBox}>
				<Box className={classes.menu}>
					<Typography className={classes.desc} variant="body2">
						Follow the steps below to invite members to your Viiision Group.
					</Typography>

					<Box className={classes.box}>
						{/* <Box className={classes.container}>
              <Typography className={classes.title} variant="body2">
                Sponsorship
              </Typography>
              <Typography variant="body2">Select how you want to pay for the member subscriptions:</Typography>
              <RadioGroup name="sponsorType" value={sponsored}>
                <Box className={classes.radioGroup}>
                  <Box className={classes.radioField} style={{ marginRight: 10 }}>
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          classes={{ root: classes.radio, checked: classes.checked }}
                          checked
                          onChange={() => {
                            setSponsored(false);
                          }}
                        />
                      }
                      label="UNSPONSORED"
                    />
                    <Typography className={classes.commentText}>
                      <span style={{ fontWeight: 'bold' }}>Unsponsored</span> Group Members pay for their own Viiision
                      subscription.
                    </Typography>
                  </Box>
                  <Box className={classes.radioField}>
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          classes={{ root: classes.radio, checked: classes.checked }}
                          disabled
                          onChange={() => {
                            setSponsored(true);
                          }}
                        />
                      }
                      label="SPONSORED"
                    />
                    <Typography className={classes.commentText}>
                      You are responsible for <span style={{ fontWeight: 'bold' }}>Sponsored</span> Group Member
                      subscriptions.
                    </Typography>
                  </Box>
                </Box>
              </RadioGroup>
              <Typography className={classes.help}>Help Me Decide</Typography>
            </Box> */}
						<Box className={classes.container}>
							<Typography className={classes.title}>Invite By Email</Typography>
							<Typography className={classes.desc}>
								Enter emails for any Group Members you would like to invite.
							</Typography>
							<EmailInputField
								emailList={emailList}
								onCancel={onCancel}
								onAdd={onEmailAdd}
								onSelect={onSelect}
							/>
						</Box>
					</Box>
				</Box>
				<Box className={classes.buttonBox}>
					<FlatButton onClick={handleClose} color="primary" className={classes.formActionButton}>
						Cancel
					</FlatButton>
					<FlatButton
						variant="contained"
						disabled={vEmailLength === 0}
						onClick={handleOpen}
						color="primary"
						className={classes.formActionButton}
					>
						Send Invites
					</FlatButton>
				</Box>
			</Box>
			<ViiisionSuccessSnackbar
				open={inviteSuccess}
				text="Invites sent successfully"
				autoHide={1000}
				onClose={() => {
					setInviteSuccess(false);
					setOpen(false);
				}}
			/>
			<ViiisionFailureSnackbar
				open={inviteFailure}
				text="Invites sent Failed"
				autoHide={2000}
				onClose={() => setInviteFailure(false)}
			/>
		</ViiisionTempDrawer>
	);
};
