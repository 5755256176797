import { INITIAL_CONTENT_DESIGNER_WALKTHROUGH, MANAGING_ELEMENTS_WALKTHROUGH } from '../../util/constants';
import { ViiisionWalkthrough } from './ViiisionWalkthrough';
import { ManagingElementsSteps } from './Steps';

export type ManagingElementsWalkthroughProps = {
	open: boolean;
	stepKey: string;
};

export const ManagingElementsWalkthrough = (props: ManagingElementsWalkthroughProps) => {
	const { open, stepKey } = { ...props };
	const show =
		open &&
		!localStorage.getItem(MANAGING_ELEMENTS_WALKTHROUGH) &&
		!!localStorage.getItem(INITIAL_CONTENT_DESIGNER_WALKTHROUGH);
	return (
		<ViiisionWalkthrough
			steps={ManagingElementsSteps}
			open={show}
			stepKey={stepKey}
			local={MANAGING_ELEMENTS_WALKTHROUGH}
		/>
	);
};
