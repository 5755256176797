import React, { useState } from 'react';
import { ViiisionMenu } from './ViiisionMenu';
import { ViiisionMenuItem } from './ViiisionMenuItem';

export type EditMenuProps = {
	navigateToApp: () => void;
	setReadOnly: (readOnly: boolean) => void;
	appName: React.MutableRefObject<any>;
};

export const EditMenu = (props: EditMenuProps) => {
	const { navigateToApp, setReadOnly, appName } = { ...props };

	const [anchor, setAnchor] = useState(null);

	const renameApp = () => {
		setReadOnly(false);
		setTimeout(() => {
			appName.current.focus();
			appName.current.select();
		}, 1);
	};

	return (
		<ViiisionMenu anchor={anchor} setAnchor={setAnchor}>
			<ViiisionMenuItem text="Edit" setAnchor={setAnchor} handleClick={navigateToApp} />
			<ViiisionMenuItem text="Rename" setAnchor={setAnchor} handleClick={renameApp} />
		</ViiisionMenu>
	);
};
