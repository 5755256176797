import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _plan } from '../../store/slices/editorSlice';
import { _topics } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { ViiisionTooltip } from '../ViiisionTooltip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageAdd: {
			backgroundColor: colors.grayLight20,
			width: '100%',
			justifyContent: 'space-between',
		},
		itemTitle: {
			fontSize: 14,
			fontWeight: 'bold',
		},
		addButton: {
			'&:hover': {
				backgroundColor: colors.blueAccent20,
				color: colors.blueAccent,
			},
			'&.Mui-disabled': {
				color: colors.grayLight,
			},
		},
	})
);

export type PageAddProps = {
	showTheAddPageComponent: () => void;
};
// This is just a button that will  show the add page menu item.
// That component is reponsible for creating the name and then firing the action to make a page (in redux)
export const PageAdd = (props: PageAddProps) => {
	const { showTheAddPageComponent } = { ...props };
	const classes = useStyles();

	const topics = useAppSelector(_topics);
	const plan = useAppSelector(_plan);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (plan?.restrictions && plan.restrictions.pageLimit) {
			setDisabled(Object.keys(topics).length >= plan.restrictions.pageLimit);
		}
	}, [plan, topics]);

	const message = disabled ? "You've reached your plan's limit of 5 pages" : 'Add Page';

	return (
		<ListItem className={classes.pageAdd} component="div">
			<Typography className={classes.itemTitle}>{Object.keys(topics).length > 1 ? 'Pages' : 'Page'}</Typography>
			<ViiisionTooltip message={message} placement="right">
				<IconButton
					disabled={disabled}
					onClick={!disabled && showTheAddPageComponent}
					size="small"
					className={classes.addButton}
					data-tour="add-page"
				>
					<Add />
				</IconButton>
			</ViiisionTooltip>
		</ListItem>
	);
};
