import { INITIAL_CONTENT_DESIGNER_WALKTHROUGH } from '../../util/constants';
import { ViiisionWalkthrough } from './ViiisionWalkthrough';
import { InitialContentDesignerSteps } from './Steps';

export type InitialContentDesignerWalkthroughProps = {
	stepKey: string;
	onClose: () => void;
};

export const InitialContentDesignerWalkthrough = (props: InitialContentDesignerWalkthroughProps) => {
	const { stepKey, onClose } = { ...props };
	const open = !localStorage.getItem(INITIAL_CONTENT_DESIGNER_WALKTHROUGH);
	return (
		<ViiisionWalkthrough
			steps={InitialContentDesignerSteps}
			open={open}
			stepKey={stepKey}
			local={INITIAL_CONTENT_DESIGNER_WALKTHROUGH}
			onClose={onClose}
		/>
	);
};
