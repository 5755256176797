import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WatchLater from '@material-ui/icons/WatchLater';
import Alert from '@material-ui/lab/Alert';
import { Skeleton } from '@mui/material';
import { useStripeState } from '../../hooks/useStripeState';
import { IS_CLOSED, IS_CLOSED_THREE_DAY } from '../../util/constants';

const useStyles = makeStyles({
	widgetAlarm: {
		color: '#4C6CDC',
		margin: '0 10px 0 0',
		height: '24px',
		width: '24px',
	},
	widgetCheck: {
		color: '#4C6CDC',
		borderRadius: '50%',
		margin: '0 10px 0 0',
		height: '24px',
		width: '24px',
	},
	messageLinkButton: {
		color: '#4C6CDC',
		cursor: 'pointer',
		background: 'none',
		margin: 0,
		padding: 0,
		border: 'none',
		font: 'Roboto',
		fontWeight: 700,
		fontSize: '14px',
	},
	subtitle: {
		fontFamily: 'Roboto, sans-serif',
		fontWeight: 500,
		fontSize: '16px',
	},
	expandedBodyNotUpgraded: {
		width: '258px',
	},
	expandedBodyUpgraded: {
		width: '268px',
	},
	alert: {
		backgroundColor: '#FFFFFF',
		marginBottom: '75px',
		boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
		zIndex: 1202,
	},
	snackbar: {
		zIndex: 1202,
	},
});

export type ExpandedViewProps = {
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
};

export const ExpandedView = (props: ExpandedViewProps) => {
	const { expanded, setExpanded } = { ...props };
	const classes = useStyles();
	const todaysDate = String(new Date().getUTCDate());
	const { openCustomerPortal, billingInfo, daysRemaining, loadingStripeData } = useStripeState();

	// set flags in local storage to indicate that the user has closed the message
	const handleClose = (event, reason?) => {
		if (reason !== 'clickaway') {
			if (daysRemaining <= 3) {
				localStorage.setItem(IS_CLOSED_THREE_DAY, todaysDate);
			} else {
				localStorage.setItem(IS_CLOSED, 'true');
			}
			setExpanded(false);
		}
	};

	const generateText = () => {
		if (billingInfo) {
			// expanded copy when billing has been added
			return (
				<Typography variant="body2" className={classes.expandedBodyUpgraded}>
					Your account is upgraded. Continue to enjoy Viiision for free for the remainder of your free trial.
					Manage your billing info from your account settings at any time.
				</Typography>
			);
		} else if (daysRemaining <= 3 && localStorage.getItem(IS_CLOSED_THREE_DAY) !== todaysDate) {
			// expanded copy when trial is ending within 3 days
			return (
				<Typography variant="body2" className={classes.expandedBodyNotUpgraded}>
					Your Viiision Portal free trial is about to end.
					<button className={classes.messageLinkButton} onClick={openCustomerPortal}>
						&nbsp;Upgrade now&nbsp;
					</button>
					to make sure you keep everything running without interruption.
				</Typography>
			);
		} else {
			// normal expanded copy
			return (
				<Typography variant="body2" className={classes.expandedBodyUpgraded}>
					Enjoy the Viiision Portal for free for {daysRemaining} day{daysRemaining !== 1 ? 's' : ''}!{' '}
					<button className={classes.messageLinkButton} onClick={openCustomerPortal}>
						Upgrade your account
					</button>{' '}
					at any time during your free trial to continue using Viiision without any interruptions.
				</Typography>
			);
		}
	};

	const generateTitle = () => {
		if (billingInfo) {
			// expanded title when billing has been added
			return <Typography className={classes.subtitle}>Account Upgraded Successfully</Typography>;
		} else if (daysRemaining <= 3 && localStorage.getItem(IS_CLOSED_THREE_DAY) !== todaysDate) {
			// expaned title when trial is ending within 3 days
			return <Typography className={classes.subtitle}>Your Free Trial Is Ending Soon</Typography>;
		} else {
			// normal expanded title
			return <Typography className={classes.subtitle}>Free Trial!</Typography>;
		}
	};

	const generateIcon = () => {
		return billingInfo ? (
			<CheckCircle className={classes.widgetCheck} />
		) : (
			<WatchLater className={classes.widgetAlarm} />
		);
	};

	return (
		<Snackbar
			open={expanded}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			className={classes.snackbar}
		>
			<Alert
				className={classes.alert}
				icon={generateIcon()}
				variant="outlined"
				severity="info"
				onClose={handleClose}
			>
				{loadingStripeData ? (
					<>
						<Skeleton animation={'wave'} width={100} height={10} />
						<Skeleton animation={'wave'} width={150} height={8} />
						<Skeleton animation={'wave'} width={80} height={8} />
					</>
				) : (
					<>
						{generateTitle()}
						{generateText()}
					</>
				)}
			</Alert>
		</Snackbar>
	);
};
