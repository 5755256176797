import React from 'react';
import { ViiisionContextMenu } from '../Dashboard/CardComponents/Menu/ViiisionContextMenu';

export type SectionContextMenuProps = {
	id: string;
	index: number;
	last: number;
	deleteSection: () => void;
	editSectionName: () => void;
	reorderSection: (payload: { oldIndex: number; newIndex: number }) => void;
};

export const SectionContextMenu: React.FC<SectionContextMenuProps> = (props) => {
	const { children, id, index, last, deleteSection, editSectionName, reorderSection } = { ...props };

	return (
		<ViiisionContextMenu
			id={id}
			options={[
				{
					text: 'Rename',
					handleClick: editSectionName,
				},
				{
					text: 'Delete',
					disabled: last === 0,
					handleClick: deleteSection,
				},
				{
					text: 'Move Up',
					disabled: index === 0,
					handleClick: () => reorderSection({ oldIndex: index, newIndex: index - 1 }),
				},
				{
					text: 'Move Down',
					disabled: index === last,
					handleClick: () => reorderSection({ oldIndex: index, newIndex: index + 1 }),
				},
			]}
		>
			{children}
		</ViiisionContextMenu>
	);
};
