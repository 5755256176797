import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { colors } from '../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		alert: {
			margin: 8,
			backgroundColor: colors.hotPurpleAccent20,
		},
		icon: {
			color: colors.hotPurpleAccent,
		},
		fixed: {
			position: 'fixed',
			bottom: 40,
			right: 40,
			zIndex: 10000,
		},
		fixedLeft: {
			position: 'fixed',
			bottom: 40,
			left: 40,
			zIndex: 10000,
		},
	})
);

export type ViiisionDismissableTipProps = {
	name?: string;
	fixed?: boolean;
	fixedLeft?: boolean;
	onClose?: () => void;
};

const defaultProps = {
	fixed: false,
	onClose: () => {},
};

export const ViiisionDismissableTip: React.FC<ViiisionDismissableTipProps> = (props) => {
	const { children, name, fixed, fixedLeft, onClose } = { ...defaultProps, ...props };
	const [showAlert, setShowAlert] = useState(!localStorage.getItem(name));
	const classes = useStyles();

	const handleClose = () => {
		name && localStorage.setItem(name, 'true');
		onClose();
		setShowAlert(false);
	};

	const component = showAlert ? (
		<Alert
			className={classes.alert}
			icon={<EmojiObjectsIcon className={classes.icon} />}
			action={
				<IconButton
					onClick={() => {
						handleClose();
					}}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			{children}
		</Alert>
	) : null;

	if (fixed) {
		return <div className={classes.fixed}>{component}</div>;
	} else if (fixedLeft) {
		return <div className={classes.fixedLeft}>{component}</div>;
	} else {
		return component;
	}
};
