import axios from 'axios';

// get Viiision plan by name
export const getPlanByName = (planName: string) => {
	return axios.post(process.env.REACT_APP_LIIINGO_URL + '/plan/getPlanByName', { planName }).then((res) => res.data);
};

// get Viiision plan that has a matching planId for it's monthly or annual plan
export const getPlanByPriceId = (priceId: string) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/plan/getPlanByPriceId', { priceId })
		.then((res) => res.data);
};
