import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningIcon from '@material-ui/icons/Warning';
import { useCallback, useState } from 'react';
import { DropzoneInputProps, DropzoneOptions, DropzoneRootProps, FileRejection, useDropzone } from 'react-dropzone';
import { FieldValues } from 'react-hook-form';
import ViiisionLogo from '../components/PhoneViewer/ViiisionStatusbarLogo';
import { colors } from '../theme/palette';
import { ACCEPTABLE_IMAGE_FILE_EXTENSIONS, ACCEPTABLE_IMAGE_MIME_TYPES, LOGO_RADIUS } from '../util/constants';

const defaultProps = {
	acceptedFileTypes: ACCEPTABLE_IMAGE_MIME_TYPES,
	fieldLabel: '',
	setDirtiness: () => {},
	maxFileSizeBytes: 3000000, // limiting file size to 3000kb because Viiision rejects POST uploads larger than 3MB
	validateImage: () => null, // Returning null implies that all validation rules were successful
};

const getImageInfo = async (file: File) => {
	return new Promise<ImageInfo>((resolve) => {
		let reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.onload = () => {
				resolve({
					width: img.width,
					height: img.height,
					sizeInBytes: file.size,
				});
			};
			img.src = reader.result as string;
		};

		reader.readAsDataURL(file);
	});
};

const useStyles = makeStyles<Theme, { backgroundColor?: string; borderColor?: string }>({
	imageOptionContent: {
		height: '100%',
		width: 'auto',
		maxWidth: '100%',
		objectFit: 'contain',
	},
	dropZone: (props) => ({
		backgroundColor: props.backgroundColor,
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.grayMedium,
		display: 'flex',
		width: 50,
		height: 50,
		borderRadius: LOGO_RADIUS,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		overflow: 'hidden',
	}),
	dragState: (props) => ({
		backgroundColor: props.backgroundColor,
		borderColor: props.borderColor,
		borderWidth: 1,
		borderStyle: 'dashed',
		display: 'flex',
		width: 50,
		height: 50,
		borderRadius: LOGO_RADIUS,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		overflow: 'hidden',
	}),
	successUpload: (props) => ({
		backgroundColor: props.backgroundColor,
		borderColor: colors.greenDark,
		borderWidth: 1,
		borderStyle: 'dashed',
		display: 'flex',
		width: 50,
		height: 50,
		borderRadius: LOGO_RADIUS,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		overflow: 'hidden',
	}),
	icon: (props) => ({
		fontSize: 'default',
		color: props.borderColor,
	}),
});

type LogoUploadFieldProps = {
	getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
	getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
	fileTypes: string;
	imagePreviewUrl: string;
	isDragActive: boolean;
	success: boolean;
	failure: boolean;
	name: string;
	label: string;
	isDrag?: boolean;
	isValidType?: boolean;
};

export type ImageInfo = {
	width: number;
	height: number;
	sizeInBytes: number;
};

/**
 * A validation function that should return either an error message or null (if all validation rules pass successfully)
 */
export type ImageValidator = (imageInfo: ImageInfo) => string | null;

export type UseLogoUploadFieldProps<TFieldValues extends FieldValues = FieldValues> = {
	initialValue?: string;
	fieldName: Extract<keyof TFieldValues, string>;
	fieldLabel?: string;
	acceptedFileTypes?: DropzoneOptions['accept'];
	maxFileSizeBytes?: number;
	setDirtiness?: (fieldName: keyof TFieldValues, isDirty: boolean) => void;
	validateImage?: ImageValidator;
	setImagePreview: (file: File) => void;
	isDrag?: boolean;
	isValidType?: boolean;
};

export const useLogoUploadField = <TFieldValues extends FieldValues = FieldValues>(
	props: UseLogoUploadFieldProps<TFieldValues>
) => {
	const {
		setDirtiness,
		fieldName,
		fieldLabel,
		initialValue,
		maxFileSizeBytes,
		validateImage,
		setImagePreview,
		isDrag,
		isValidType,
	} = {
		...defaultProps,
		...props,
	};

	const [fieldHelperText, setFieldHelperText] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState('');
	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const readableFileTypes = ACCEPTABLE_IMAGE_FILE_EXTENSIONS;

	const handleSuccess = () => {
		setSuccess(true);
		setTimeout(() => {
			setSuccess(false);
		}, 3000);
	};

	const handleFailure = () => {
		setFailure(true);
		setTimeout(() => {
			setFailure(false);
		}, 3000);
	};

	const handleErrorMessage = (rejectedFiles: FileRejection[]) => {
		setErrorMessage(
			rejectedFiles.map(
				(rejectedFile) =>
					rejectedFile.errors.map((error) => {
						switch (error.code) {
							case 'file-too-large':
								return `File is too large.`;
							case 'too-many-files':
								return 'Too many files.';
							case 'file-invalid-type':
								return 'Invalid file type.';
							default:
								return error.message;
						}
					})[0]
			)[0]
		);
		setFieldHelperText(
			rejectedFiles.map(
				(rejectedFile) =>
					rejectedFile.errors.map((error) => {
						switch (error.code) {
							case 'file-too-large':
								return `Upload a file that is ${(maxFileSizeBytes / 1000000).toFixed(0)}MB or less.`;
							case 'too-many-files':
								return 'You can only drop 1 image here';
							case 'file-invalid-type':
								let readable = ACCEPTABLE_IMAGE_FILE_EXTENSIONS.split(
									','
								).reduce((total, current, index, arr) =>
									arr[index + 1] ? `${total}, ${current}` : `${total} and ${current}`
								);
								return `The image added isn't a supported format. Only ${readable} image formats can be added.`;
							default:
								return error.message;
						}
					})[0]
			)[0]
		);
	};

	const onDropAccepted: DropzoneOptions['onDropAccepted'] = useCallback(
		async (acceptedFiles) => {
			let imageInfo: Promise<ImageInfo>;
			acceptedFiles.forEach((file) => {
				imageInfo = getImageInfo(file);
				setImagePreview(file);
			});
			const validationErrorMessage = validateImage(await imageInfo);
			setFieldHelperText(validationErrorMessage ?? '');
			setDirtiness(fieldName, true);
			handleSuccess();
		},
		[fieldName, setDirtiness, validateImage, setImagePreview]
	);

	const onDropRejected: DropzoneOptions['onDropRejected'] = (rejectedFiles) => {
		handleFailure();
		handleErrorMessage(rejectedFiles);
	};

	const fieldRequirementsText = (maxFileSizeBytes / 1000000).toFixed(1) + 'MB max';

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDropAccepted,
		onDropRejected,
		accept: ACCEPTABLE_IMAGE_FILE_EXTENSIONS,
		maxFiles: 1,
		maxSize: maxFileSizeBytes,
	});

	const logoUploadFieldProps = {
		getRootProps,
		getInputProps,
		imagePreviewUrl: initialValue,
		isDragActive,
		success,
		failure,
		name: fieldName,
		label: fieldLabel,
		helperText: fieldHelperText,
		errorMessage,
		fieldRequirementsText,
		fileTypes: readableFileTypes,
		isDrag,
		isValidType,
	};

	return [logoUploadFieldProps, LogoUploadField] as const;
};

const LogoUploadField = ({
	getRootProps,
	getInputProps,
	fileTypes,
	imagePreviewUrl,
	isDragActive,
	success,
	failure,
	name,
	isDrag,
	isValidType,
}: LogoUploadFieldProps) => {
	var styling: { backgroundColor: string; borderColor: string };

	if (success) {
		styling = {
			backgroundColor: colors.greenLight,
			borderColor: colors.greenDark,
		};
	} else if (failure) {
		styling = {
			backgroundColor: colors.redLight,
			borderColor: colors.redDark,
		};
	} else if (isDragActive) {
		styling = {
			backgroundColor: colors.tealAccent20,
			borderColor: colors.tealAccent,
		};
	} else if (isDrag) {
		styling = {
			backgroundColor: colors.pureWhite,
			borderColor: colors.tealAccent,
		};
	} else {
		styling = {
			backgroundColor: colors.pureWhite,
			borderColor: colors.grayLight,
		};
	}

	const classes = useStyles(styling);

	return (
		<>
			<div
				className={(isDragActive || isDrag) && isValidType ? classes.dragState : classes.dropZone}
				{...getRootProps()}
			>
				{success ? (
					<SuccessfulUpload />
				) : failure ? (
					<FailedUpload />
				) : (
					<UploadField
						getInputProps={getInputProps}
						imagePreviewUrl={imagePreviewUrl}
						isDragActive={isDragActive || isDrag}
						name={name}
						isValidType={isValidType}
					/>
				)}
			</div>
		</>
	);
};

const UploadField = (
	props: Omit<
		LogoUploadFieldProps,
		'getRootProps' | 'success' | 'failure' | 'helperText' | 'errorMessage' | 'label' | 'fileTypes'
	>
) => {
	const { isDragActive, imagePreviewUrl, isValidType } = { ...props };
	const classes = useStyles({ borderColor: isDragActive ? colors.tealAccent : colors.grayLight });
	const [isOver, setIsOver] = useState(false);

	return (
		<div
			style={{ width: '100%' }}
			onDragEnter={() => {
				setIsOver(true);
			}}
			onDragLeave={() => {
				setIsOver(false);
			}}
			className={classes.dropZone}
		>
			{isDragActive && isOver && isValidType ? (
				<CloudDownloadIcon className={classes.icon} />
			) : imagePreviewUrl ? (
				<Avatar alt="logo" src={imagePreviewUrl} className={classes.imageOptionContent} />
			) : (
				<ViiisionLogo />
			)}
		</div>
	);
};

const SuccessfulUpload = () => {
	const classes = useStyles({ borderColor: colors.greenDark });
	return (
		<div className={classes.successUpload}>
			<CheckCircleIcon className={classes.icon} />
		</div>
	);
};

const FailedUpload = () => {
	const classes = useStyles({ borderColor: colors.redDark });
	return (
		<>
			<WarningIcon className={classes.icon} />
		</>
	);
};
