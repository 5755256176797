import React, { useState } from 'react';
import { ViiisionSuccessSnackbar } from '../../../ViiisionSuccessSnackbar';
import { ViiisionMenu } from './ViiisionMenu';
import { ViiisionMenuItem } from './ViiisionMenuItem';
import { downloadImage } from '../../../../util/downloadQrcode';
import { useAppSelector } from '../../../../store/hooks';
import { _topics } from '../../../../store/slices/topicSlice';

export type QRMenuProps = {
	topicId: string;
};

export const QRMenu = (props: QRMenuProps) => {
	const { topicId } = { ...props };
	const [copySuccess, setCopySuccess] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const topics = useAppSelector(_topics);
	const selectedTopic = topics[topicId];

	const copyToClipboard = () => {
		navigator.clipboard.writeText(process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + topicId);
		setCopySuccess(true);
	};

	return (
		<>
			<ViiisionMenu anchor={anchor} setAnchor={setAnchor} name="QRMenu">
				<ViiisionMenuItem text="Copy Link" handleClick={copyToClipboard} setAnchor={setAnchor} />
				<ViiisionMenuItem
					text="Download PNG"
					handleClick={() => downloadImage(topicId, selectedTopic.name[0].name, 'png')}
					setAnchor={setAnchor}
				/>
				<ViiisionMenuItem
					text="Download SVG"
					handleClick={() => downloadImage(topicId, selectedTopic.name[0].name, 'svg')}
					setAnchor={setAnchor}
				/>
			</ViiisionMenu>
			<ViiisionSuccessSnackbar
				open={copySuccess}
				text="Link copied successfully"
				onClose={() => setCopySuccess(false)}
			/>
		</>
	);
};
