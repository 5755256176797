import { IconButton, ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ContentCopy from '@material-ui/icons/FileCopyOutlined';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _selectedTopic } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { ViiisionSuccessSnackbar } from '../ViiisionSuccessSnackbar';
import { ViiisionTooltip } from '../ViiisionTooltip';
import { drawerCollapsedWidth } from './ViiisionDrawer';
import { downloadImage } from '../../util/downloadQrcode';
import PNGIcon from '../../theme/png.svg';
import SVGIcon from '../../theme/svg.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			backgroundColor: colors.grayLight20,
		},
		titleFont: {
			fontSize: 14,
			fontWeight: 'bold',
		},
		list: {
			paddingLeft: drawerCollapsedWidth,
			paddingTop: 0,
		},
		download: {
			'&:hover': {
				backgroundColor: colors.purpleAccent20,
				color: colors.purpleAccent,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.purpleAccent,
			},
		},
		copy: {
			'&:hover': {
				backgroundColor: colors.tealAccent20,
				color: colors.tealAccent,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.tealAccent,
			},
		},
		actions: {
			display: 'flex',
		},
		qr: {
			width: '100%',
			padding: 15,
		},
	})
);

export const QrMenu: React.FC = () => {
	const topic = useAppSelector(_selectedTopic);
	const [copySuccess, setCopySuccess] = useState(false);
	const classes = useStyles();

	const copy = () => {
		navigator.clipboard.writeText(process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + topic.id);
		setCopySuccess(true);
	};

	return (
		<>
			<List className={classes.list}>
				<ListItem className={classes.title}>
					<Typography className={classes.titleFont}>QR Code</Typography>
					<ListItemSecondaryAction>
						<Box className={classes.actions}>
							<ViiisionTooltip message="Download" placement="bottom">
								<>
									<IconButton
										disableRipple
										size="small"
										className={classes.download}
										onClick={() => downloadImage(topic.id, topic.name[0].name, 'png')}
									>
										<img src={PNGIcon} alt="PNG download icon" width="24" height="24" />
									</IconButton>

									<IconButton
										disableRipple
										size="small"
										className={classes.download}
										onClick={() => downloadImage(topic.id, topic.name[0].name, 'svg')}
									>
										<img src={SVGIcon} alt="SVG download icon" width="24" height="24" />
									</IconButton>
								</>
							</ViiisionTooltip>
							<ViiisionTooltip message="Copy Link" placement="bottom">
								<IconButton disableRipple size="small" className={classes.copy} onClick={copy}>
									<ContentCopy fontSize="small" />
								</IconButton>
							</ViiisionTooltip>
						</Box>
					</ListItemSecondaryAction>
				</ListItem>
				<ViiisionTooltip
					placement="right"
					message="Scan this code with a mobile device to view the page you're currently designing."
				>
					<img
						src={process.env.REACT_APP_LIIINGO_URL + '/exhibit/qr?id=' + topic?.id}
						alt="QR Code"
						className={classes.qr}
					/>
				</ViiisionTooltip>
			</List>
			<ViiisionSuccessSnackbar
				open={copySuccess}
				text="Link copied successfully"
				onClose={() => setCopySuccess(false)}
			/>
		</>
	);
};
