import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { colors } from '../theme/palette';
import { ViiisionTooltip, ViiisionTooltipPosition } from './ViiisionTooltip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: colors.redDark,
		},
	})
);

export type ViiisionWarningProps = {
	message: string;
	placement?: ViiisionTooltipPosition;
};

export const ViiisionWarning = (props: ViiisionWarningProps) => {
	const classes = useStyles();
	const defaultProps: ViiisionWarningProps = {
		message: 'Warning',
		placement: 'bottom-start',
	};
	const { message, placement } = { ...defaultProps, ...props };

	return (
		<ViiisionTooltip placement={placement} message={message}>
			<ErrorIcon className={classes.icon} />
		</ViiisionTooltip>
	);
};
