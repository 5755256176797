import { INITIAL_PAGE_PANEL_WALKTHROUGH } from '../../util/constants';
import { INITIAL_CONTENT_DESIGNER_WALKTHROUGH } from '../../util/constants';
import { ViiisionWalkthrough } from './ViiisionWalkthrough';
import { InitialPagePanelSteps } from './Steps';

export type InitialPagePanelWalkthroughProps = {
	stepKey: string;
};

export const InitialPagePanelWalkthrough = (props: InitialPagePanelWalkthroughProps) => {
	const { stepKey } = { ...props };
	const open =
		localStorage.getItem(INITIAL_CONTENT_DESIGNER_WALKTHROUGH) &&
		!localStorage.getItem(INITIAL_PAGE_PANEL_WALKTHROUGH);
	return (
		<ViiisionWalkthrough
			steps={InitialPagePanelSteps}
			open={open}
			stepKey={stepKey}
			local={INITIAL_PAGE_PANEL_WALKTHROUGH}
		/>
	);
};
