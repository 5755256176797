import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import stripBom from 'strip-bom';
import Stripe from 'stripe';
import { LinkDialogMenuOption } from '../../components/SettingsMenuOptions/Dialog/LinkDialog';
import { PhoneOption, PhoneScheme } from '../../components/SettingsMenuOptions/Dialog/LinkDialogComponents/Phone';
import { SETTINGS_MENU } from '../../util/constants';
import * as api from '../api-client';
import { createSubscriptionParams } from '../api-client';
import { Language } from '../models/location/Language';
import { AppState } from '../store';
import { addTopic, deleteTopic } from './topicSlice';

export interface EditorState {
	saving: boolean;
	saveSuccess: boolean;
	saveFailure: boolean;
	activeLanguageCode: Language;
	focusedItemName: string | null;
	focusedTopicId: string | null;
	leftDrawerMenu: string;
	linkDialogMenu: LinkDialogMenuOption;
	buttonLink: {
		invalid: boolean;
		web: string;
		email: string;
		phone: PhoneOption;
	};
	unsavedChanges: boolean;
	newLanguageIds: { [language: string]: string[] }; // a Map object, keyed by language code, values are topic id arrays. Indicates an object has a language that is new and should render at 50% opaque.
	mlOpen: boolean;
	cropImageUrl: string | null;
	tipQueue: string[];
	leftDrawerWidth: number;
	newDefaultLanguage: Language; // see locationSlice, saveLanguages
	newSupportedLanguages: Language[]; // see locationSlice, saveLanguages
	showAddPageNameInput: boolean;
	plan: any; // setting this to any because I'm lazy
	stripeCustomer: Stripe.Customer;
	stripeSubscription: Stripe.Subscription;
	loadingStripeData: boolean;
	redirecting: boolean;
	isActionDelete: boolean;
}

const initEditorState: EditorState = {
	saving: false,
	saveSuccess: false,
	saveFailure: false,
	activeLanguageCode: 'en' as Language,
	focusedItemName: SETTINGS_MENU,
	focusedTopicId: '',
	leftDrawerMenu: 'page',
	linkDialogMenu: 'web',
	buttonLink: {
		invalid: true,
		web: '',
		email: '',
		phone: {
			scheme: 'tel',
			number: '',
		},
	},
	unsavedChanges: false,
	newLanguageIds: {},
	mlOpen: false,
	cropImageUrl: '',
	tipQueue: [],
	leftDrawerWidth: 328,
	newDefaultLanguage: null as Language,
	newSupportedLanguages: [] as Language[],
	showAddPageNameInput: false,
	plan: null, // { name: 'Unspecified', restrictions: null },
	stripeCustomer: {} as Stripe.Customer,
	stripeSubscription: {} as Stripe.Subscription,
	loadingStripeData: false,
	redirecting: false,
	isActionDelete: false,
};

interface ChangeButtonPayload {
	link: string;
	linkOption: LinkDialogMenuOption;
	phoneScheme?: PhoneScheme;
}

export const fetchStripeInfo = createAsyncThunk<
	{ stripeCustomer: Stripe.Customer; stripeSubscription: Stripe.Subscription },
	void,
	{ state: AppState }
>('editor/fetchStripeInfo', async (_, { getState, dispatch }) => {
	let stripeCustomer = await api.getCustomer();
	let stripeSubscription = await api.getSubscription(stripeCustomer.id);
	return { stripeCustomer, stripeSubscription };
});

export const createCustomerSubscription = createAsyncThunk<
	{ stripeCustomer: Stripe.Customer; stripeSubscription: Stripe.Subscription },
	createSubscriptionParams,
	{ state: AppState }
>('editor/createCustomerSubscription', async (payload: createSubscriptionParams, { getState, dispatch }) => {
	let { email, priceId, coupon, trialDays } = payload;
	let { stripeCustomer, stripeSubscription } = await api.createCustomerSubscription(
		email,
		priceId,
		coupon,
		trialDays
	);
	return { stripeCustomer, stripeSubscription };
});

const editorSlice = createSlice({
	name: 'editor',
	initialState: initEditorState,
	reducers: {
		setRedirecting: (state, action: PayloadAction<boolean>) => {
			state.redirecting = action.payload; // assumed to be true
			// reverts back to false after 5 seconds
			setTimeout(
				() => {
					state.redirecting = false;
				},
				5000,
				state
			);
		},
		setPlan(state: EditorState, action: PayloadAction<any>) {
			state.plan = action.payload;
		},
		getPlanByPriceId(state: EditorState, action: PayloadAction<any>) {
			// action is intercepted by viiision middleware in store.ts.
			// middleware will make the network request and modify the payload to include the plan.
			state.plan = action.payload;
		},
		getPlanByName(state: EditorState, action: PayloadAction<any>) {
			// action is intercepted by Viiision middleware in store.ts.
			// middleware will make the network request and modify the payload to include the plan.
			state.plan = action.payload;
		},
		setNewSupportedLanguages: (state, action: PayloadAction<Language[]>) => {
			state.newSupportedLanguages = action.payload;
		},
		setNewDefaultLanguage: (state: EditorState, action: PayloadAction<Language>) => {
			state.newDefaultLanguage = action.payload;
		},
		setActiveLanguageCode: (state: EditorState, action: PayloadAction<Language>) => {
			state.activeLanguageCode = action.payload;
		},
		focusedItemName: (state: EditorState, action: PayloadAction<string>) => {
			state.focusedItemName = action.payload;
		},
		editorTopicFocused: (state: EditorState, action: PayloadAction<string>) => {
			state.focusedTopicId = action.payload;
		},
		clearFocusItem: (state: EditorState) => {
			state.focusedItemName = '';
		},
		changeLeftDrawerMenu: (state: EditorState, action: PayloadAction<string>) => {
			state.leftDrawerMenu = action.payload;
		},
		linkDialogMenuChange: (state: EditorState, action: PayloadAction<LinkDialogMenuOption>) => {
			state.linkDialogMenu = action.payload;
		},
		buttonLinkChange: (state: EditorState, action: PayloadAction<ChangeButtonPayload>) => {
			action.payload.link = stripBom(action.payload.link); //remove hidden BOM char
			if (action.payload.linkOption === 'phone') {
				state.buttonLink.phone.number = action.payload.link;
				state.buttonLink.phone.scheme = action.payload.phoneScheme;
			} else {
				state.buttonLink[action.payload.linkOption] = action.payload.link;
			}
		},
		buttonLinkInvalid: (state: EditorState, action: PayloadAction<boolean>) => {
			state.buttonLink.invalid = action.payload;
		},
		saveSuccessfully: (state: EditorState, action: PayloadAction<boolean>) => {
			state.saveSuccess = action.payload;
		},
		saveFailure: (state: EditorState, action: PayloadAction<boolean>) => {
			state.saveFailure = action.payload;
		},
		isSaving: (state: EditorState, action: PayloadAction<boolean>) => {
			state.saving = action.payload;
		},
		setDrawerWidth: (state: EditorState, action: PayloadAction<number>) => {
			state.leftDrawerWidth = action.payload;
		},
		makeChange: (state: EditorState, action: PayloadAction<boolean>) => {
			state.unsavedChanges = action.payload;
		},
		addSingleLanguageElement: (state: EditorState, action: PayloadAction<{ language: Language; id: string }>) => {
			//i.e new page created, french is a second language, add the new page id to the "french" array
			const { language, id } = action.payload;
			// if the array is nullish, set the array to empty
			state.newLanguageIds[language] = state.newLanguageIds[language] || [];
			// add the id to the array
			state.newLanguageIds[language].push(id);
		},
		removeSingleLanguageElement: (
			state: EditorState,
			action: PayloadAction<{ language: Language; id: string }>
		) => {
			const { language, id } = action.payload;
			const index = state.newLanguageIds[language].indexOf(id);
			state.newLanguageIds[language].splice(index, 1);
		},
		removeAllLanguageIds: (state: EditorState, action: PayloadAction) => {
			// this is a map of language codes to arrays of topic ids
			state.newLanguageIds = {};
		},
		addNewLanguageIds: (state: EditorState, action: PayloadAction<{ language: Language; ids: string[] }>) => {
			// this tracks every entity that received a clone of default language
			// called when adding a new language via Multilanguage selection tool
			// example: newLanguageIds = {es: [...ids], fr: [...ids], ...}
			const { language, ids } = action.payload;
			//if the array is nullish, set the array to empty
			let lIds = state.newLanguageIds[language] || [];
			//add all ids to the array
			lIds = [...lIds, ...ids];
			//set the new array
			state.newLanguageIds[language] = lIds;
		},
		removeNewLanguageIds: (state: EditorState, action: PayloadAction<{ language: Language; ids: string[] }>) => {
			// this removes every id that has been focused (acknowledged) or removed via MultilanguageDeleteDialog
			const { language, ids } = action.payload;
			let lIds = state.newLanguageIds[language];
			//if there are no ids, then there's nothing to remove
			if (!lIds) {
				return;
			}
			//remove all ids from the array
			ids.forEach((id) => {
				const index = lIds.indexOf(id);
				lIds.splice(index, 1);
			});
			//set the new array
			state.newLanguageIds[language] = lIds;
		},
		toggleMlDrawer: (state: EditorState, action: PayloadAction<boolean>) => {
			state.mlOpen = action.payload;
		},
		setCropImageUrl: (state: EditorState, action: PayloadAction<string>) => {
			state.cropImageUrl = action.payload;
		},
		addTip: (state: EditorState, action: PayloadAction<string>) => {
			state.tipQueue.push(action.payload);
		},
		removeTip: (state: EditorState, action: PayloadAction) => {
			state.tipQueue.pop();
		},
		setShowAddPageNameInput: (state: EditorState, action: PayloadAction<boolean>) => {
			state.showAddPageNameInput = action.payload;
		},
		setStripeCustomer: (state: EditorState, action: PayloadAction<Stripe.Customer>) => {
			state.stripeCustomer = action.payload;
		},
		setStripeSubscription: (state: EditorState, action: PayloadAction<Stripe.Subscription>) => {
			state.stripeSubscription = action.payload;
		},
		setIsActionDelete: (state: EditorState, action: PayloadAction<boolean>) => {
			state.isActionDelete = action.payload;
		},
	},
	extraReducers: (builder) => {
		// fetch stripe info states
		builder.addCase(fetchStripeInfo.pending, (state, action) => {
			//PENDING
			state.loadingStripeData = true;
		});
		builder.addCase(fetchStripeInfo.fulfilled, (state, action) => {
			// FULFILLED
			let { stripeCustomer, stripeSubscription } = action.payload;
			state.stripeCustomer = stripeCustomer;
			state.stripeSubscription = stripeSubscription;
			state.loadingStripeData = false;
		});
		builder.addCase(fetchStripeInfo.rejected, (state, action) => {
			//REJECTED
			// TODO: handle error
			state.loadingStripeData = false;
			console.error('Error fetching Stripe info', action.error);
		});

		// create customer and subscription
		builder.addCase(createCustomerSubscription.pending, (state, action) => {
			//PENDING
			state.loadingStripeData = true;
		});
		builder.addCase(createCustomerSubscription.fulfilled, (state, action) => {
			//FULFILLED
			let { stripeCustomer, stripeSubscription } = action.payload;
			state.stripeCustomer = stripeCustomer;
			state.stripeSubscription = stripeSubscription;
			state.loadingStripeData = false;
		});
		builder.addCase(createCustomerSubscription.rejected, (state, action) => {
			//REJECTED
			// TODO: handle error
			state.loadingStripeData = false;
			console.error('Error creating Stripe customer and/or subscription', action.error);
		});

		// generalized, add any actions that save using this logic
		// these are used to show or hide a toast message. This isn't efficient or obvious.
		// TODO: use hot toast
		builder
			// pending
			.addMatcher(isAnyOf(addTopic.pending, deleteTopic.pending), (state) => {
				state.saving = true;
			})
			// fulfilled
			.addMatcher(isAnyOf(addTopic.fulfilled, deleteTopic.fulfilled), (state) => {
				state.saving = false;
				state.saveSuccess = true;
			})
			// rejected
			.addMatcher(isAnyOf(addTopic.rejected, deleteTopic.rejected), (state) => {
				state.saving = false;
				state.saveFailure = true;
			});
	},
});

// here's you a reducer
export default editorSlice.reducer;

//here's you sum actions
export const {
	setActiveLanguageCode,
	editorTopicFocused,
	focusedItemName,
	clearFocusItem,
	changeLeftDrawerMenu,
	linkDialogMenuChange,
	buttonLinkChange,
	buttonLinkInvalid,
	saveSuccessfully,
	saveFailure,
	isSaving,
	makeChange,
	addSingleLanguageElement,
	removeSingleLanguageElement,
	removeAllLanguageIds,
	addNewLanguageIds,
	removeNewLanguageIds,
	toggleMlDrawer,
	setCropImageUrl,
	addTip,
	removeTip,
	setDrawerWidth,
	setNewDefaultLanguage,
	setNewSupportedLanguages,
	setShowAddPageNameInput,
	setPlan,
	setStripeCustomer,
	setStripeSubscription,
	setRedirecting,
	setIsActionDelete,
	getPlanByName,
	getPlanByPriceId,
} = editorSlice.actions;

export const _activeLanguageCode = (state) => state.editor.activeLanguageCode;
export const _currentLanguages = (state) => state.editor.currentLanguages;
export const _focusedItemName = (state) => state.editor.focusedItemName;
export const _leftDrawerMenu = (state) => state.editor.leftDrawerMenu;
export const _linkDialogMenu = (state) => state.editor.linkDialogMenu;
export const _buttonLink = (state) => state.editor.buttonLink;
export const _buttonLinkInvalid = (state) => state.editor.buttonLink.invalid;
export const _saveSuccess = (state) => state.editor.saveSuccess;
export const _saveFailure = (state) => state.editor.saveFailure;
export const _saving = (state) => state.editor.saving;
export const _unsavedChanges = (state) => state.editor.unsavedChanges;
export const _newLanguageIds = (state) => state.editor.newLanguageIds;
export const _mlOpen = (state) => state.editor.mlOpen;
export const _cropImageUrl = (state) => state.editor.cropImageUrl;
export const _tips = (state) => state.editor.tipQueue;
export const _leftDrawerWidth = (state) => state.editor.leftDrawerWidth;
export const _showAddPageNameInput = (state) => state.editor.showAddPageNameInput; //show an input in the page list to enter the new page name
export const _stripeCustomer = (state) => state.editor.stripeCustomer;
export const _stripeSubscription = (state) => state.editor.stripeSubscription;
export const _loadingStripeData = (state) => state.editor.loadingStripeData;
export const _redirecting = (state) => state.editor.redirecting;
export const _isActionDelete = (state) => state.editor.isActionDelete;

// These are used to manage ML drawer. Instead of modifying the Location directly while using the drawer, we modify some temp variables that are only used after the user confimrs their selection.
export const _newDefaultLanguage = (state) => state.editor.newDefaultLanguage; //what will be set on the location.defaultLanguage
export const _newSupportedLanguages = (state) => state.editor.newSupportedLanguages; //what will be set on the location.supportedLanguages
export const _plan = (state) => state.editor.plan;
