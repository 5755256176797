import Box from '@mui/material/Box';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { TopContentType } from '../../../../store/models';
import { TopicMap } from '../../../../store/slices/topicSlice';
import { colors } from '../../../../theme/palette';
import { ViiisionTooltip } from '../../../ViiisionTooltip';
import { ChartContent } from './ChartContent';
import { useAppSelector } from '../../../../store/hooks';
import { _defaultLanguage } from '../../../../store/slices/locationSlice';
import { useEffect, useState } from 'react';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bar: {
			height: 5,
			width: '100%',
		},
		progressBox: {
			width: 312,
		},
		labelBox: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'space-between',
			marginTop: 15,
			marginBottom: 5,
		},
		infoBox: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		divider: {
			marginRight: 2,
			marginLeft: 2,
		},
		increase: {
			color: colors.greenDark,
		},
		decrease: {
			color: colors.redDark,
		},
	})
);

// export type MostViewedData = {
// 	title: string;
// 	total: number;
// 	change?: number;
// };

export type MostViewedProps = {
	data?: TopContentType[]; //sorted from greatest to least
	topics?: TopicMap;
};

export const MostViewed = (props: MostViewedProps) => {
	const { data, topics } = { ...props };
	const classes = useStyles();
	let [sessionCount, setSessionCount] = useState(0);
	// const sessionCount = data[0]?.value;
	const defaultLanguage = useAppSelector(_defaultLanguage);
	const pageNames = Object.values(topics).map(
		(topic) => topic.name.find((lang) => lang.language === defaultLanguage).name
	);

	useEffect(() => {
		if (data) {
			setSessionCount(data[0]?.value);
		}
	}, [data]);

	return (
		<ChartContent
			name={'MOST VIEWED'}
			label="Pages"
			toolTipMsg={'The most viewed pages in your Viiision, ordered from most to least.'}
		>
			{data?.length > 0 ? (
				data.slice(0, data.length > 5 ? 5 : data.length).map((dataPoint, index) => (
					<Box key={index} className={classes.progressBox}>
						<Box className={classes.labelBox}>
							<Typography variant="caption">{dataPoint.label}</Typography>
							<Box className={classes.infoBox}>
								{dataPoint.change ? (
									<>
										{dataPoint.change < 0 ? (
											<ArrowDownwardIcon fontSize="inherit" className={classes.decrease} />
										) : (
											<ArrowUpwardIcon fontSize="inherit" className={classes.increase} />
										)}
										<Typography
											className={dataPoint.change < 0 ? classes.decrease : classes.increase}
											variant="caption"
										>
											{`${Math.abs(dataPoint.change)}%`}
										</Typography>
									</>
								) : (
									<ViiisionTooltip
										placement="top"
										message="Not enough data to compare viewing history."
									>
										<Typography variant="caption">-- %</Typography>
									</ViiisionTooltip>
								)}

								<Divider className={classes.divider} orientation="vertical" />
								<Typography variant="caption">{dataPoint.value}</Typography>
							</Box>
						</Box>
						<LinearProgress
							className={classes.bar}
							variant="determinate"
							value={(dataPoint.value / sessionCount) * 100}
						/>
					</Box>
				))
			) : (
				<ViiisionTooltip placement="top" message="Not enough data to compare viewing history.">
					<>
						{pageNames.slice(0, pageNames.length > 5 ? 5 : pageNames.length).map((pageName, index) => (
							<Box key={index} className={classes.progressBox}>
								<Box className={classes.labelBox}>
									<Typography variant="caption">{pageName}</Typography>
									<Box className={classes.infoBox}>
										<Typography variant="caption">-- %</Typography>
										<Divider className={classes.divider} orientation="vertical" />
										<Typography variant="caption"> 0</Typography>
									</Box>
								</Box>
								<LinearProgress className={classes.bar} variant="determinate" value={0} />
							</Box>
						))}
					</>
				</ViiisionTooltip>
			)}
		</ChartContent>
	);
};
