import Box from '@mui/material/Box';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setNewDefaultLanguage, setNewSupportedLanguages } from '../../store/slices/editorSlice';
import { saveLanguages } from '../../store/slices/locationSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { ViiisionTempDrawer } from '../ViiisionTempDrawer';
import { MultilanguageSearch } from './MLSearch';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			width: 264,
		},
		content: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			width: 557,
			height: '75%',
			marginLeft: 10,
		},
		buttonBox: {
			marginBottom: 75,
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 80,
		},
		button: {
			height: 40,
			marginRight: 10,
		},
	})
);

// Think of the ML drawer as a component that only acts upon the Location slice
// The location slice will handle adding/removing languages for its children
// So, we'll maintain a list of language codes to be added, and another list of language codes to be removed.
// To keep the location slice clean and to match the style of the other slices (section, topic, content),
// we'll use the editor slice to store the 2 lists mentioned above
// When the drawer is closed via the save button, we'll dispatch the 'saveLanguages' action from the location slice
// It will operate by using the 2 lists found in the editor slice. It will also trigger actions to add/remove languages to/from it's children
// It is triggered by clicking "save" in the ML drawer
// Closing the drawer any other way than "save" should simply clear the 2 lists in editor slice, but does not dispatch saveLanguages.
// Another editor slice variable will be used to store changes to the default language. "newDefaultLanguage"
// The actual default language can only be changed by the saveLanguages action. It will read what ever is stored in the newDefaultLanguage varibale.
// And yet another editor slice variable will be used to store changes to the supported languages. "newSupportedLanguages"
// The actual supported languages can only be changed by the saveLanguages action. It will read what ever is stored in the newSupportedLanguages varibale.
export type MultilanguageDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const MultilanguageDrawer = (props: MultilanguageDrawerProps) => {
	const { open, setOpen } = { ...props };
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const saveLanguagesAction = bindActionCreators(saveLanguages, dispatch);
	const updateNewSupportedLanguages = bindActionCreators(setNewSupportedLanguages, dispatch);
	const updateNewDefaultLanguage = bindActionCreators(setNewDefaultLanguage, dispatch);

	return (
		<ViiisionTempDrawer open={open} setOpen={setOpen} title={'Multilingual'}>
			<Box className={classes.content}>
				<Typography className={classes.text} variant="body2">
					Make your content available to more people by adding additional languages.
				</Typography>
				<MultilanguageSearch />
				<Box className={classes.buttonBox}>
					<FlatButton
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={() => {
							updateNewSupportedLanguages([]);
							updateNewDefaultLanguage(null);
							setOpen(false);
						}}
					>
						Cancel
					</FlatButton>
					<FlatButton
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={() => {
							saveLanguagesAction();
							setOpen(false);
						}}
					>
						Save
					</FlatButton>
				</Box>
			</Box>
		</ViiisionTempDrawer>
	);
};
export default MultilanguageDrawer;
