// QRGeneratorCardWrapper >> QRGeneratorTable >> QRGeneratorTableIcons >> LiteEditQRModal >> LiteForm
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { updateRedirect } from '../../store/slices/redirectSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { LiteModal } from './LiteModal';
import { Box, TextField, Typography } from '@material-ui/core';
import { TableRowData } from './QRGeneratorTable';

// useStyles

const useStyles = makeStyles({
	iconWrapper: {
		display: 'inline-flex',
		justifyContent: 'space-between',
	},
	box: {
		display: 'flex',
		flexDirection: 'column',
		padding: 15,
	},
	text: {
		fontSize: 16,
		paddingTop: 8,
		width: '95%',
		'& .MuiTextField-root': {
			margin: 15,
		},
	},
});

// props
export type LiteEditQRModalProps = {
	handleClose: () => void;
	open: boolean;
	rowData: TableRowData;
};

const LiteEditQRModal = (props: LiteEditQRModalProps) => {
	const { handleClose, open, rowData } = { ...props };
	// consts, methods, functions, useEffects, useState ect go here
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const updateBackendRowData = bindActionCreators(updateRedirect, dispatch);

	const [newLinkName, setNewLinkName] = useState(rowData.name);
	const [newURL, setNewURL] = useState(rowData.url);

	const handleEdit = () => {
		updateBackendRowData({ name: newLinkName, id: rowData.id, url: newURL });
		handleClose();
	};

	return (
		<div className={classes.iconWrapper}>
			<LiteModal
				title="Edit QR Code"
				titleVariant="dark"
				handleClose={handleClose}
				open={open}
				actions={
					<>
						<FlatButton onClick={handleClose}>Cancel</FlatButton>
						<FlatButton variant="contained" color="primary" type="submit" onClick={handleEdit}>
							Update
						</FlatButton>
					</>
				}
			>
				{/* Display edit info based on ID of clicked QR code */}

				<Box className={classes.box}>
					<Typography className={classes.text} variant="body2">
						{'Update your dynamic QR code by editing the fields below, without generating a new code.'}
					</Typography>
				</Box>

				<div className={classes.text}>
					<TextField
						label={'Link Name'}
						placeholder="My QR Code Nickname"
						id="linkName"
						value={newLinkName}
						variant="outlined"
						size="small"
						fullWidth
						onChange={(e) => {
							setNewLinkName(e.target.value);
						}}
					/>
					<TextField
						label="Link"
						placeholder="https://www.viiision.com"
						id="linkInput"
						value={newURL}
						variant="outlined"
						size="small"
						fullWidth
						onChange={(e) => {
							setNewURL(e.target.value);
						}}
					/>
				</div>
			</LiteModal>
		</div>
	);
};

export default LiteEditQRModal;
