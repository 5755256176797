import { Button, createStyles, Theme } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WatchLater from '@material-ui/icons/WatchLater';
import { Skeleton } from '@mui/material';
import { useStripeState } from '../../hooks/useStripeState';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		widgetButton: {
			border: `1px solid #4C6CDC`, // Use a color from the Theme once the theme has been added
			textTransform: 'none',
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
		},
		widgetButtonDisabled: {
			borderColor: '#4C6CDC',
			textTransform: 'none',
			pointerEvents: 'none',
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
		},
		widgetClock: {
			color: '#4C6CDC',
			margin: '0 10px 0 0',
			height: '24px',
			width: '24px',
		},
		widgetSpinner: {
			color: '#4C6CDC',
			margin: '0 10px 0 0',
		},
		tooltipWidth: {
			maxWidth: '350px',
		},
		snackbar: {
			marginRight: '75px',
			zIndex: 1202,
		},
		tooltipBody: {
			color: colors.pureWhite,
		},
	})
);

export const DaysView = () => {
	const classes = useStyles();
	const { daysRemaining, billingInfo, openCustomerPortal, loadingStripeData } = useStripeState();

	const generateHoverMsg = () => {
		return billingInfo ? (
			<Typography variant="body2" className={classes.tooltipBody}>
				Your account is upgraded. Continue to enjoy Viiision for free for the remainder of your free trial.
			</Typography>
		) : (
			<Typography variant="body2" className={classes.tooltipBody}>
				Click to upgrade.
			</Typography>
		);
	};

	return (
		<Tooltip arrow placement={'left'} title={generateHoverMsg()} classes={{ tooltip: classes.tooltipWidth }}>
			<Snackbar
				open={true}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				className={classes.snackbar}
			>
				<Button
					className={billingInfo ? classes.widgetButtonDisabled : classes.widgetButton}
					variant="contained"
					onClick={openCustomerPortal}
				>
					<WatchLater className={classes.widgetClock} />
					{loadingStripeData ? (
						<Skeleton animation={'wave'} width={50} />
					) : (
						<Typography variant="body1">
							{daysRemaining} Day{daysRemaining === 1 ? '' : 's'} Left
						</Typography>
					)}
				</Button>
			</Snackbar>
		</Tooltip>
	);
};
