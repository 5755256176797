import Box from '@mui/material/Box';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Language } from '../../store/models';
import { colors } from '../../theme/palette';
import { ViiisionTooltip } from '../ViiisionTooltip';
import { MultilanguageMenu } from './MLMenu';
import { LANGUAGE_MAP } from '../../store/models/location/Language';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItemLight: {
			margin: 0,
			backgroundColor: colors.pureWhite,
		},
		listItemDark: {
			margin: 0,
			backgroundColor: colors.grayLight5,
		},
		primary: {
			marginLeft: 10,
		},
		content: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			height: '100%',
		},
		language: {
			display: 'flex',
			alignItems: 'center',
		},
	})
);

export type MultilanguageListItemProps = {
	primary: boolean;
	language: Language;
	count: number;
	index: number;
	supported: Language[];
	setSupported: (supported: Language[]) => void;
	removeLanguage: (language: Language) => void;
	changePrimary: (language: Language) => void;
};

export const MultilanguageListItem = (props: MultilanguageListItemProps) => {
	const { primary, language, count, index, supported, setSupported, removeLanguage, changePrimary } = { ...props };
	const classes = useStyles();

	return (
		<ViiisionTooltip message="Right-click for language options" placement="bottom">
			<ListItem
				disableGutters
				className={index % 2 ? classes.listItemLight : classes.listItemDark}
				key={language + 'mlListItemTotallyUniqueName'}
			>
				<Box className={classes.content}>
					<Box className={classes.language}>
						<Typography variant="body2">{LANGUAGE_MAP[language]}</Typography>
						{primary && <Chip className={classes.primary} color="secondary" label="Primary" />}
					</Box>
					{count > 1 && (
						<MultilanguageMenu
							primary={primary}
							languageCode={language}
							supported={supported}
							setSupported={setSupported}
							removeLanguage={removeLanguage}
							changePrimary={changePrimary}
						/>
					)}
				</Box>
			</ListItem>
		</ViiisionTooltip>
	);
};
