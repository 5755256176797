import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';
import { Language, newTitle, newTopic, Section, Status, Title, Topic } from '../models';
import { AppState } from '../store';
import { contentDelete, ContentReorderPayload } from './contentSlice';
import { deleteTopicFromSection, selectSection, updateTopicOrder } from './sectionSlice';

export interface TopicMap {
	[id: string]: Topic;
}
export interface TopicState {
	topics: TopicMap;
	selectedTopicId: string;
	isLoading: boolean;
	loadingQrs: string[];
}

const initTopicState: TopicState = {
	topics: {} as TopicMap,
	selectedTopicId: null,
	isLoading: false,
	loadingQrs: [],
};

export type BackgroundPayload = {
	name: string;
	url: string;
};

export type UploadImage = {
	preview: string;
	file: File;
};

// ADD A NEW TOPIC
// Must have a section first, it's a required parameter
// provide a page name if you want to
export const addTopic = createAsyncThunk<
	Topic,
	{ section: Section; index: number; pageName?: string },
	{ state: AppState }
>('topic/addTopic', async (payload: { section: Section; index: number; pageName?: string }, { getState, dispatch }) => {
	const sectionId = payload.section.id;
	const topicOrder = payload.section.topicOrder;
	const pageCount = payload.section.topicOrder.length;
	// if no page name is provided, use a default numbered page name
	const pageName = payload.pageName?.length > 0 ? payload.pageName : `Page ${pageCount + 1}`;
	const { locations, selectedLocationId } = getState().location;
	const languages = locations[selectedLocationId].supportedLanguages;
	const organizationId = locations[selectedLocationId].organizationId;
	let topic = createNewTopic(pageName, languages, sectionId, organizationId);

	// need to add topic to "topics" (TopicMap) before we add it to the section.topicOrder array
	dispatch(addNewTopic(topic));

	// make copy of array
	let newTopicOrder = [...topicOrder];
	// modify copy, starting at payload.index, remove 0 items and insert topic.id
	newTopicOrder.splice(payload.index, 0, topic.id);
	dispatch(updateTopicOrder({ sectionId, topicOrder: newTopicOrder }));

	return topic;
});

type ReorderTopicPayload = { oldIndex: number; oldSectionId: string; newIndex: number; newSectionId: string };

// REORDER TOPICS
export const reorderTopic = createAsyncThunk<
	{ sectionId: string; topicId: string },
	ReorderTopicPayload,
	{ state: AppState }
>('topic/reorderTopic', async (payload: ReorderTopicPayload, { getState, dispatch }) => {
	let reorderPayload: { sectionId: string; topicId: string };
	// if we're trying to drop it in the original position, do nothing
	if (payload.oldSectionId === payload.newSectionId && payload.oldIndex === payload.newIndex) {
		return;
	} else {
		// SAME SECTION
		if (payload.oldSectionId === payload.newSectionId) {
			let topicOrder = structuredClone(getState().section.sections[payload.oldSectionId].topicOrder);
			const tempTopicId = topicOrder[payload.oldIndex];
			topicOrder.splice(payload.oldIndex, 1);
			topicOrder.splice(payload.newIndex, 0, tempTopicId);
			dispatch(updateTopicOrder({ sectionId: payload.oldSectionId, topicOrder }));
			reorderPayload = { sectionId: payload.oldSectionId, topicId: tempTopicId };
		} else {
			// CROSS SECTION
			let oldSectionTopicOrder = structuredClone(getState().section.sections[payload.oldSectionId].topicOrder);
			const tempTopicId = oldSectionTopicOrder[payload.oldIndex];
			// change sectionId in topic
			oldSectionTopicOrder.splice(payload.oldIndex, 1);
			dispatch(updateTopicOrder({ sectionId: payload.oldSectionId, topicOrder: oldSectionTopicOrder }));
			// add tempTopicId to newSection
			let newSectionTopicOrder = structuredClone(getState().section.sections[payload.newSectionId].topicOrder);
			newSectionTopicOrder.splice(payload.newIndex, 0, tempTopicId);
			dispatch(updateTopicOrder({ sectionId: payload.newSectionId, topicOrder: newSectionTopicOrder }));
			reorderPayload = { sectionId: payload.newSectionId, topicId: tempTopicId };
			dispatch(selectSection(payload.newSectionId));
		}
	}

	return reorderPayload;
});

// DELETE A TOPIC
// First delete the content
// Now, delete the Topic
export const deleteTopic = createAsyncThunk<string, string, { state: AppState }>(
	'topic/deleteTopic',
	async (topicId: string, { getState, dispatch }) => {
		const topics = getState().topic.topics;
		let topic = topics[topicId];
		const sections = getState().section.sections;
		let sectionId = topic.sectionId;
		let section = sections[sectionId];
		let selectedTopicId = getState().topic.selectedTopicId;

		// if we're deleting the selected topic, select another topic
		// if a user deletes a page, select the next page
		// if there is no next page, select the previous page
		// if there is no previous page, select first available page in another section,
		// or no-op if there are no other pages
		if (selectedTopicId === topicId) {
			const topicIndex = section.topicOrder.findIndex((id) => id === topicId);
			let nextTopicId;
			if (section.topicOrder.length === 1) {
				// if last page of this section
				nextTopicId = Object.keys(topics).find((id) => id !== topicId); //returns first match or null if no other pages
			} else if (topicIndex < section.topicOrder.length - 1) {
				// if not the last page
				nextTopicId = section.topicOrder[topicIndex + 1]; // select next page
			} else {
				nextTopicId = section.topicOrder[topicIndex - 1]; // select previous page, we know there is one
			}
			nextTopicId && dispatch(selectTopic(nextTopicId)); // if nextTopicId is truthy, dispatch the action
		}

		// update the section's topicOrder
		dispatch(deleteTopicFromSection({ sectionId, topicId }));
		// return topicId to trigger extraReducer and update this slice
		return topicId;
	}
);

// DELETE TOPICS
export const deleteTopics = createAsyncThunk<string[], string[], { state: AppState }>(
	'topic/deleteTopics',
	async (topicIds: string[], { getState, dispatch }) => {
		const topics = getState().topic.topics;
		let contentIds = [];
		topicIds.forEach((topicId) => {
			// collect contentIds from the topic we're deleting
			contentIds = [...contentIds, ...topics[topicId].content];
			// delete the topic
			dispatch(deleteTopic(topicId));
		});
		// delete all the contents.
		// TODO: disable this if potential for deleting shared content
		dispatch(contentDelete(contentIds));
		return topicIds; //not used in extra reducer
	}
);

// TOPIC SLICE
// Remember, it's only ok to modify state within these reducers because there's behind-the-scenes stuff going on that allows it.
// Normally, and anywhere else, we don't modify state directly, but rather via dispatching an action
const topicSlice = createSlice({
	name: 'topic',
	initialState: initTopicState,
	// REGULAR REDUCERS
	reducers: {
		fetchTopics(state: TopicState, action: PayloadAction<Topic[]>) {
			let topics = action.payload;
			topics.forEach((topic) => {
				state.topics[topic.id] = topic;
			});
		},
		// This is being used to add the topic to the store via dispatch from the Thunk.
		// It would normally be added by returning the Topic, which would trigger the Extra-Reducer that contains this logic. Order of operations insists I do it this way instead.
		addNewTopic(state: TopicState, action: PayloadAction<Topic>) {
			state.topics[action.payload.id] = action.payload;
			state.selectedTopicId = action.payload.id;
		},
		addQr(state: TopicState, action: PayloadAction<{ id: string; qr: string }>) {
			const { id, qr } = action.payload;
			if (state.topics[id]) {
				state.topics[id].qr = qr;
			}
		},

		dropCreateNewTopicFlag(state: TopicState, action: PayloadAction<{ id: string }>) {
			if (!state.topics[action.payload.id]) {
				return;
			}
			delete state.topics[action.payload.id].createNewEntity;
		},
		removeLanguagesFromTopics(state: TopicState, action: PayloadAction<Language[]>) {
			const removeLanguagesList = action.payload;
			//for every topic, filter the names of the languages to remove
			Object.values(state.topics).forEach((topic) => {
				topic.name = topic.name.filter((title) => !removeLanguagesList.includes(title.language));
			});
		},
		addLanguagesToTopics(
			state: TopicState,
			action: PayloadAction<{ languages: Language[]; languageToCopy: Language }>
		) {
			const { languages, languageToCopy } = action.payload;
			// iterate over all the topics
			Object.values(state.topics).forEach((topic) => {
				// If the language we're adding already exists, remove it. Happens when data is corrupt.
				topic.name = topic.name.filter((item) => !languages.includes(item.language));
				const nameToCopy = topic.name.find((item) => item.language === languageToCopy);
				// ffor every language that needs added, make a copy of the nameToCopy
				languages.forEach((lang) => {
					const newName = { ...nameToCopy, language: lang };
					topic.name.push(newName);
				});
			});
		},
		topicContentReorder(state: TopicState, action: PayloadAction<ContentReorderPayload>) {
			const selectedTopic = state.topics[state.selectedTopicId];
			const contentToReorder = selectedTopic.content[action.payload.oldIndex];
			selectedTopic.content.splice(action.payload.oldIndex, 1);
			selectedTopic.content.splice(action.payload.newIndex, 0, contentToReorder);
		},
		topicContentAdd(state: TopicState, action: PayloadAction<{ id: string; index: number }>) {
			const { id, index } = action.payload;
			const topic = state.topics[state.selectedTopicId];
			topic.content.splice(index, 0, id);
		},
		topicContentDelete(state: TopicState, action: PayloadAction<string>) {
			const topic = state.topics[state.selectedTopicId];
			const index = topic.content.indexOf(action.payload);
			topic.content.splice(index, 1);
		},
		changeBackground(state: TopicState, action: PayloadAction<BackgroundPayload>) {
			state.topics[state.selectedTopicId].backgroundImageName = action.payload.name;
			state.topics[state.selectedTopicId].exhibitImage = action.payload.url;
			state.isLoading = false;
		},
		topicEnableSharing(state: TopicState, action: PayloadAction<{ topicId: string; enableSharing: boolean }>) {
			const { topicId, enableSharing } = action.payload;
			state.topics[topicId].enableSharing = enableSharing;
		},
		topicSetStatus(state: TopicState, action: PayloadAction<{ topicId: string; status: Status }>) {
			const { topicId, status } = action.payload;
			state.topics[topicId].status = status;
		},
		renameTopic(state: TopicState, action: PayloadAction<{ topicId: string; title: Title }>) {
			const { topicId, title } = action.payload;
			const topic = state.topics[topicId];
			const translation = topic.name.find((t) => t.language === title.language);
			translation.name = title.name;
		},
		duplicateTopic(state: TopicState, action: PayloadAction<Partial<Topic>>) {
			state.topics[action.payload.id] = action.payload;
			state.selectedTopicId = action.payload.id;
		},
		saveTopic(state: TopicState, action: PayloadAction<{ topicId: string }>) {
			// this reducer does absolutley nothing. The action is intercepted by the Viiision Middleware in store.ts, where it will fire a network save
			// this was added for handling the onBlur event when changing the page name
		},
		updateTopic(state: TopicState, action: PayloadAction<Topic>) {
			state.topics[action.payload.id] = action.payload;
		},
		selectTopic(state: TopicState, action: PayloadAction<string>) {
			state.selectedTopicId = action.payload;
		},
		setIsLoading(state: TopicState, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
	},
	// EXTRA REDUCERS
	extraReducers: (builder) => {
		builder
			.addCase(addTopic.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(reorderTopic.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(addTopic.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(reorderTopic.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(addTopic.fulfilled, (state, action) => {
				// topic already added to the store via normal reducer, prior to the Thunk being fulfilled
				state.isLoading = false;
			})
			.addCase(deleteTopic.fulfilled, (state, action) => {
				delete state.topics[action.payload];
			})
			.addCase(deleteTopics.fulfilled, (state, action) => {})
			.addCase(reorderTopic.fulfilled, (state, action) => {
				state.isLoading = false;
				const topic = state.topics[action.payload?.topicId];
				if (!!topic) {
					topic.sectionId = action.payload.sectionId;
				}
			});
	},
});

// Export reducer
export default topicSlice.reducer;

// Export actions
export const {
	fetchTopics,
	dropCreateNewTopicFlag,
	addNewTopic,
	topicContentReorder,
	topicContentAdd,
	topicContentDelete,
	changeBackground,
	removeLanguagesFromTopics,
	addLanguagesToTopics,
	renameTopic,
	topicEnableSharing,
	topicSetStatus,
	saveTopic,
	updateTopic,
	duplicateTopic,
	selectTopic,
	addQr,
	setIsLoading,
} = topicSlice.actions;

// Export selectors
export const _topics = (state) => state.topic.topics;
export const _selectedTopicId = (state) => state.topic.selectedTopicId;
export const _selectedTopic = (state) => state.topic.topics[state.topic.selectedTopicId];
export const _selectedTopicName = (state, language: Language) => {
	const { topics, selectedTopicId } = state.topic;
	const name = topics[selectedTopicId]?.name;
	return name?.find((title) => title.language === language)?.name;
};
export const _topicIdToContentList = (state) => {
	const topicIdToContentList = {};
	Object.keys(state.topic.topics).forEach((topicId) => {
		const topic = state.topic.topics[topicId];
		topicIdToContentList[topicId] = topic.content;
	});
	return topicIdToContentList;
};
export const _topicIsLoading = (state) => state.topic.isLoading;
export const _topicBackground = (state) => state.topic.topics[state.topic.selectedTopicId]?.exhibitImage;
export const _loadingQrs = (state) => state.topic.loadingQrs;

// Helper method for addTopic Thunk above
export const createNewTopic = (pageName: string, language: string[], sectionId: string, organizationId: string) => {
	const languageNames = language.map((code: Language) => {
		return newTitle({ name: pageName, language: code });
	});
	const topic = newTopic({
		id: new ObjectID().toHexString(), //bcuz we only need the string
		background: '',
		backgroundImageName: '',
		content: [],
		enableSharing: true,
		exhibitImage: '',
		loc: null,
		name: languageNames,
		organizationId: organizationId,
		qr: '',
		sectionId: sectionId,
		slideshowSettings: { defaultSeconds: 10, contentSeconds: {} },
		status: 1,
		createNewEntity: true,
	});

	return topic;
};
