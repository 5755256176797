import Bugsnag from '@bugsnag/js';
import Container from '@material-ui/core/Container';
import InputBase from '@material-ui/core/InputBase';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LeftDrawer from '../components/AppEditor/LeftDrawer';
import { drawerWidth } from '../components/AppEditor/ViiisionDrawer';
import { MenuSelect } from '../components/AppEditor/MenuSelect';
import RightDrawer from '../components/AppEditor/RightDrawer';
import { SaveButton } from '../components/AppEditor/SaveButton';
import { FocusableEditorField } from '../components/Editable/EditorField';
import { Focusable } from '../components/Editable/Focusable';
import { EnumTags } from '../components/ElementMenu/InsertTypographyOption';
import { LeavePageDialog } from '../components/LeavePageDialog';
import { ViiisionFailureSnackbar } from '../components/ViiisionFailureSnackbar';
import { ViiisionSuccessSnackbar } from '../components/ViiisionSuccessSnackbar';
import { ViiisionTooltip } from '../components/ViiisionTooltip';
import LanguageDropdown from '../components/ML/LanguageDropDown';
import { AppBarHeight } from '../components/Navigation/AppBar';
import { TemplateEditor } from '../components/TemplateEditor';
import { InactiveTip, IncognitoTip, MultilanguageTip, TemporaryTip } from '../components/Tips';
import { InitialContentDesignerWalkthrough } from '../components/Walkthroughs/InitialContentDesignerWalkthrough';
import { ManagingElementsWalkthrough } from '../components/Walkthroughs/ManagingElementsWalkthrough';
import { useHydrateStore } from '../hooks/useHydrateStore';
import { useQueryStringParams } from '../hooks/useQueryStringParams';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { Content, FileType, Location, Topic } from '../store/models';
import {
	ContentMap,
	ContentReorderPayload,
	_contentIsLoading,
	_contents,
	_selectedContent,
	addContentPayload,
	addNewContent,
	contentReorder,
} from '../store/slices/contentSlice';
import {
	_focusedItemName,
	_leftDrawerMenu,
	_leftDrawerWidth,
	_saveFailure,
	_saveSuccess,
	_saving,
	_tips,
	_unsavedChanges,
	changeLeftDrawerMenu,
	clearFocusItem,
	focusedItemName,
	makeChange,
	removeTip,
	saveSuccessfully,
	saveFailure as setSaveFailure,
} from '../store/slices/editorSlice';
import {
	_headerLogo,
	_locationIsLoading,
	_selectedLocation,
	_supportedLanguages,
	changeLocationName,
	saveEverything,
	saveLocation,
} from '../store/slices/locationSlice';
import { _selectedTopic, _selectedTopicId, _topicBackground, topicContentReorder } from '../store/slices/topicSlice';
import { Keyframes } from '../theme/Keyframes';
import { colors } from '../theme/palette';
import {
	APP_NAME,
	HEADER_LOGO,
	INITIAL_CONTENT_DESIGNER_WALKTHROUGH,
	MANAGING_ELEMENTS_WALKTHROUGH,
	NEW_LANGUAGE_CONTENT_TIP,
	ORG_NAME,
	PAGE_HIDDEN_TIP,
	PAGE_INCOGNITO_TIP,
	PAGE_NAME_ID as PAGE_NAME,
	PAGE_TEMPORARY_TIP,
	SECTION_NAME_ID as SECTION_NAME,
	TOPIC_BACKGROUND,
} from '../util/constants';

import { TranslateButton } from '../components/AppEditor/TranslateButton';
import { useIsDev } from '../hooks/useIsDev';
import NotFound from './NotFound';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appName: {
			fontFamily: 'Open Sans, sans-serif',
			fontSize: 24,
			lineHeight: 32,
			color: colors.grayDark,
			border: 0,
			display: 'grid',
		},
		container: {
			paddingRight: drawerWidth,
			paddingLeft: drawerWidth,
			paddingTop: AppBarHeight,
		},
		box: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			position: 'relative',
			marginBottom: 40,
		},
		headerBox: {
			zIndex: 1100,
			display: 'flex',
			flexDirection: 'row',
			height: 60,
			justifyContent: 'space-between',
			paddingLeft: 20,
			top: 40,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #92A0AC',
			width: '100%',
			marginBottom: 40,
		},
		headerBottomBox: {
			zIndex: 1100,
			display: 'flex',
			flexDirection: 'row',
			height: 20,
			background: 'linear-gradient(180deg, #FAFAFB 5.21%, rgba(250, 250, 251, 0) 100%)',
			position: 'fixed',
			top: 100,
		},
		bottomBox: {
			height: 40,
		},
		text: {
			color: colors.pureWhite,
		},
		//adding a brief spinner to the page while it loads
		loader: {
			content: "''",
			padding: '150px',
			width: '48px',
			height: '48px',
			borderRadius: '50%',
			display: 'inline-block',
			borderTop: '4px solid #D0D4DA',
			borderRight: '4px solid transparent',
			boxSizing: 'border-box',
			animation: 'rotation 1s linear infinite',
			'&:after': {
				content: "''",
				padding: '150px',
				boxSizing: 'border-box',
				position: 'absolute',
				left: 0,
				top: 0,
				width: '48px',
				height: '48px',
				borderRadius: '50%',
				borderLeft: '4px solid #181B20',
				borderBottom: '4px solid transparent',
				animation: 'rotation 0.5s linear infinite reverse',
			},
		},
	})
);

type AppEditorProps = {
	leftDrawerMenu: string;
	location: Location;
	loading: boolean;
	focusedName?: string;
	logo: string;
	topicBackground: string;
	onFocusedItemName: (contentId: string) => void;
	addNewContent: (payload: addContentPayload) => void;
	onReorderContent: (payload: ContentReorderPayload) => void;
	onReorderTopicContent: (payload: ContentReorderPayload) => void;
	onLeftMenuClick: (menuOption: string) => void;
	contentLoading: boolean;
	saving: boolean;
	saveSuccess: boolean;
	saveFailure: boolean;
	changeAppName: (name: string) => void;
	setSaveSuccess: (event?, reason?) => void;
	setSaveFailure: (event?, reason?) => void;
	supportedLanguages: string[];
	unsavedChanges: boolean;
	makeChange: (payload: boolean) => void;
	selectedTopic: Topic;
	clearFocusItem: () => void;
	removeTip: () => void;
	tips: string[];
	leftDrawerWidth: number;
	selectedContent: Content;
};

const AppEditor = ({
	leftDrawerMenu,
	location,
	loading,
	focusedName = '',
	logo,
	topicBackground,
	onFocusedItemName,
	addNewContent,
	onReorderContent,
	onReorderTopicContent,
	onLeftMenuClick,
	saving,
	saveSuccess,
	saveFailure,
	changeAppName,
	setSaveSuccess,
	setSaveFailure,
	supportedLanguages,
	unsavedChanges,
	makeChange,
	selectedTopic,
	clearFocusItem,
	removeTip,
	tips,
	leftDrawerWidth,
}: AppEditorProps) => {
	const classes = useStyles();
	const [editorFields, setEditorFields] = useState([]);
	const [scrollPos] = useState(0);
	const [stepKey, setStepKey] = useState('');
	const [openManagingElements, setOpenManagingElements] = useState(false);
	const [highlightImage, setHighlightImage] = useState(!localStorage.getItem(INITIAL_CONTENT_DESIGNER_WALKTHROUGH));
	const history = useHistory();
	const urlParams = useQueryStringParams();
	const [firstLogin, setFirstLogin] = useState(!!urlParams.get('firstLogin') ?? false);
	const [paymentAdded, setPaymentAdded] = useState(!!urlParams.get('paymentAdded') ?? false);

	const isDev = useIsDev();

	const contents: ContentMap = useAppSelector(_contents);
	const selectedTopicId = useAppSelector(_selectedTopicId);
	const dispatch = useAppDispatch();
	const saveItAll = bindActionCreators(saveEverything, dispatch);
	const saveAppName = bindActionCreators(saveLocation, dispatch);

	const headerWidth = window.innerWidth - leftDrawerWidth - 328;
	const handleReorder = (oldIndex: number, newIndex: number) => {
		setStepKey('end');
		onReorderContent({ oldIndex: oldIndex, newIndex: newIndex });
		onReorderTopicContent({ oldIndex: oldIndex, newIndex: newIndex });
	};

	const handleFocus = useCallback((id: string) => {
		let showWalkthrough = (!FileType[id] || !!EnumTags[id]) && (!!FileType[id] || !EnumTags[id]) && id !== 'name';
		if (showWalkthrough && id !== PAGE_NAME && id !== SECTION_NAME) {
			setOpenManagingElements(true);
		}
	}, []);

	const handleAdd = (payload) => {
		addNewContent(payload);
	};

	// THE MOTHER OF ALL SAVING
	const saveAll = () => {
		makeChange(false);
		saveItAll();
	};

	// This is where the content items for a page are put together, based on their type
	useEffect(() => {
		if (!selectedTopic) return null;
		const fields = [];
		for (const [index, contentId] of selectedTopic.content.entries()) {
			const content = contents[contentId];
			if (!!content) {
				fields.push(
					<Draggable key={content._id} draggableId={content._id} index={index}>
						{(provided, snapshot) => (
							<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
								<FocusableEditorField
									id={content._id}
									key={content._id + 'Focusable'}
									content={content}
									onFocus={() => handleFocus(content._id)}
									isFocused={content._id === focusedName}
								/>
							</div>
						)}
					</Draggable>
				);
			} else {
				// only show this toast in dev mode, for debugging purposes.
				if (isDev) {
					toast(`Topic ${selectedTopic.id} references contentId ${contentId} which doesn't exist.`);
				}

				console.log(`Topic ${selectedTopic.id} references contentId ${contentId} which doesn't exist.`);
				Bugsnag.notify(
					`Topic ${selectedTopic.id} references contentId ${contentId} but that content was not found in the DB`
				);
			}
		}

		setEditorFields(fields);
	}, [selectedTopic, selectedTopicId, focusedName, handleFocus, loading, contents, isDev]);

	if (!location) {
		// briefly show a loading spinner while we wait for the location to load
		return (
			<>
				<Keyframes name="rotation" _0={{ transform: 'rotate(0deg)' }} _100={{ transform: 'rotate(360deg)' }} />
				<span style={{ color: '#181B20BB', margin: '0px', position: 'absolute', left: '50vw', top: '20vh' }}>
					{' '}
					please wait...
					<span style={{ position: 'relative', left: -200, top: 150 }} className={classes.loader}></span>
				</span>
			</>
		);
	}

	if (!loading && !location) {
		return <NotFound message={`locationId not found`} />;
	}

	return (
		<>
			<InitialContentDesignerWalkthrough stepKey={stepKey} onClose={() => setHighlightImage(false)} />
			<ManagingElementsWalkthrough open={openManagingElements} stepKey={stepKey} />
			<ViiisionSuccessSnackbar
				open={firstLogin}
				text="Account Created"
				onClose={() => {
					setFirstLogin(false);
					history.replace('app-editor');
				}}
			/>
			<ViiisionSuccessSnackbar
				open={paymentAdded}
				text="Payment added successfully"
				onClose={() => {
					setPaymentAdded(false);
					history.replace(`app-editor`);
				}}
			/>
			<Container className={classes.container} disableGutters={true} maxWidth={false}>
				<LeavePageDialog open={unsavedChanges} saveAll={saveAll} makeChange={makeChange} />
				<ViiisionFailureSnackbar open={saveFailure} text="Save Failed" onClose={() => setSaveFailure(false)} />
				{/* <AppBar
					appBarItems={[
						{
							route: 'dashboard',
							displayName: 'Dashboard',
						},
						{
							route: LIIINGO_HELP_URL,
							displayName: 'Help',
						},
					]}
				/> */}
				<DragDropContext
					onDragStart={(result) => {
						if (result.draggableId === 'image') {
							setStepKey('element-menu-drag-start');
						}
						handleFocus(result.draggableId); //is this needed?
					}}
					onDragEnd={(result) => {
						if (result?.destination?.index !== undefined) {
							if (!localStorage.getItem(INITIAL_CONTENT_DESIGNER_WALKTHROUGH)) {
								localStorage.setItem(MANAGING_ELEMENTS_WALKTHROUGH, 'hide');
							} else if (focusedName !== PAGE_NAME && focusedName !== SECTION_NAME) {
								setStepKey('right-drawer');
								setOpenManagingElements(true);
							}

							setStepKey('right-drawer');
							switch (result?.draggableId) {
								case FileType[result.draggableId]:
									handleAdd({
										languages: supportedLanguages,
										contentType: FileType[result.draggableId],
										index: result.destination.index,
									});
									break;
								case EnumTags[result.draggableId]:
									handleAdd({
										languages: supportedLanguages,
										contentType: FileType.text,
										index: result.destination.index,
										tag: result.draggableId,
									});
									break;
							}

							if (result?.source?.droppableId === result?.destination?.droppableId) {
								handleReorder(result?.source?.index, result?.destination?.index);
							}
						} else if (result.draggableId === 'image') {
							setStepKey('element-menu');
						}
					}}
				>
					{loading ? null : <LeftDrawer highlightedImage={highlightImage} />}
					<MenuSelect selected={leftDrawerMenu} onLeftMenuClick={onLeftMenuClick} />
					<RightDrawer />

					<Box className={classes.box}>
						<Box className={classes.headerBox} style={{ width: headerWidth, left: leftDrawerWidth }}>
							<div
								onDoubleClick={(e) => {
									onFocusedItemName(APP_NAME);
								}}
								style={{ width: '-webkit-fill-available' }}
							>
								<Focusable
									id={APP_NAME}
									isFocused={focusedName === APP_NAME}
									onFocus={() => {}}
									label="Tagline"
									badgeVertical="bottom"
									badgeHorizontal="left"
								>
									<ViiisionTooltip message="Double-click to rename" placement="bottom">
										<InputBase
											data-tour="rename"
											className={classes.appName}
											value={location.name}
											placeholder="Tagline"
											inputProps={{
												style: {
													textOverflow: 'ellipsis',
												},
											}}
											onChange={(e) => {
												changeAppName(e.target.value);
											}}
											onBlur={(e) => {
												setStepKey('rename-complete');
												clearFocusItem();
												saveAppName();
											}}
											onKeyPress={(e) => {
												if (e.key === 'Enter') {
													clearFocusItem();
													saveAppName();
												}
											}}
											readOnly={focusedName !== APP_NAME ? true : false}
										/>
									</ViiisionTooltip>
								</Focusable>
							</div>

							<TranslateButton />
							<LanguageDropdown />
							<SaveButton
								disabled={!unsavedChanges}
								saving={saving}
								success={saveSuccess}
								setSuccess={setSaveSuccess}
								handleSave={saveAll}
							/>
						</Box>
						{scrollPos === 0 ? null : (
							<Box
								className={classes.headerBottomBox}
								style={{ width: headerWidth, left: leftDrawerWidth }}
							></Box>
						)}
						<TemplateEditor
							fields={editorFields}
							location={location}
							headerLogo={logo}
							topicBackgroundImageUrl={topicBackground}
							onSelect={handleFocus}
							isFocusedHeaderLogo={focusedName === HEADER_LOGO}
							isFocusedBackgroundImage={focusedName === TOPIC_BACKGROUND}
							isFocusedAppName={focusedName === APP_NAME}
							isFocusedOrgName={focusedName === ORG_NAME}
							isFocusedSectionName={focusedName === SECTION_NAME}
						/>
					</Box>
				</DragDropContext>
			</Container>
			{tips[tips.length - 1] === NEW_LANGUAGE_CONTENT_TIP && <MultilanguageTip removeTip={removeTip} />}
			{tips[tips.length - 1] === PAGE_HIDDEN_TIP && <InactiveTip removeTip={removeTip} />}
			{tips[tips.length - 1] === PAGE_INCOGNITO_TIP && <IncognitoTip removeTip={removeTip} />}
			{tips[tips.length - 1] === PAGE_TEMPORARY_TIP && <TemporaryTip removeTip={removeTip} />}
		</>
	);
};

const AppEditorContainer = () => {
	const dispatch = useAppDispatch();
	useHydrateStore();

	const locationIsLoading = useAppSelector(_locationIsLoading);
	const location = useAppSelector(_selectedLocation);
	const logo = useAppSelector(_headerLogo);
	const contentLoading = useAppSelector(_contentIsLoading);
	const saving = useAppSelector(_saving);
	const leftDrawerMenu = useAppSelector(_leftDrawerMenu);
	const focusedName = useAppSelector(_focusedItemName);
	const selectedContent = useAppSelector(_selectedContent);
	const background = useAppSelector(_topicBackground);
	const saveSuccess = useAppSelector(_saveSuccess);
	const saveFailure = useAppSelector(_saveFailure);
	const supportedLanguages = useAppSelector(_supportedLanguages);
	const unsavedChanges = useAppSelector(_unsavedChanges);
	const selectedTopic = useAppSelector(_selectedTopic);
	const tips = useAppSelector(_tips);
	const leftDrawerWidth = useAppSelector(_leftDrawerWidth);

	const state = {
		leftDrawerMenu,
		location,
		loading: locationIsLoading,
		contentLoading,
		saving,
		focusedName,
		logo,
		topicBackground: background,
		selectedContent,
		saveSuccess,
		saveFailure,
		supportedLanguages,
		unsavedChanges,
		selectedTopic,
		tips,
		leftDrawerWidth,
	};

	const actions = bindActionCreators(
		{
			addNewContent,
			onReorderContent: contentReorder,
			onReorderTopicContent: topicContentReorder,
			onFocusedItemName: focusedItemName,
			onLeftMenuClick: changeLeftDrawerMenu,
			changeAppName: changeLocationName,
			setSaveSuccess: saveSuccessfully,
			setSaveFailure: setSaveFailure,
			makeChange,
			clearFocusItem,
			removeTip,
		},
		dispatch
	);

	return (
		<div style={{ animation: 'fade 1.5s ease .5s both' }}>
			<Keyframes name="fade" _0={{ opacity: 0 }} _100={{ opacity: 1 }} />
			<AppEditor {...state} {...actions} />
		</div>
	);
};

export default AppEditorContainer;
