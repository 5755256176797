import axiosInstance from '.';
import { newNotification, PushNotification } from '../models';

export const sendPushNotification = async (notification: PushNotification) => {
	const res = await axiosInstance.post('/notification/save', notification);
	return res.data;
};
export const getPushNotifications = async (topicTypeId: string) => {
	let res = await axiosInstance.post('/notification/get', { topicTypeId: topicTypeId });
	let notifications = res.data.map((notification: any) => newNotification(notification));
	return notifications;
};

export const getPushNotificationsByLocationId = async (locationId: string) => {
	let res = await axiosInstance.post('/notification/getByLocationId', { locationId: locationId });
	let notifications = res.data.map((notification: any) => newNotification(notification));
	return notifications;
};

export const deletePushNotification = (id: string) => {
	return axiosInstance.post('/notification/deleteNotification', { id: id }).then((res: any) => res.data);
};

// export const sendPushNotification = (payload) => {
//   return axiosInstance.post('/user-notification/sendPushNotification', payload).then((res: any) => res.data);
// };

// export const sendPushNotification = async (notification: pushNotification) => {
//   const res = await axiosInstance.post('/notification/save', notification);
//   return res.data;
// };

// FROM CAT, NEEDS CLEANED UP

// public async getScheduledPushNotifications(topicId: string): Promise<pushNotificationResponse[]> {
//   const accessToken = await this.authenticate();
//   const response = await this.viiisionApi
//     .post(
//       '/notification/get',
//       {
//         topicTypeId: topicId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )
//     .catch(produceViiisionApiError('Error getting scheduled push notifications'));
//   return response.data;
// }

// public async deletePushNotification(notificationId: string): Promise<boolean> {
//   const accessToken = await this.authenticate();
//   const response = await this.viiisionApi
//     .post(
//       '/notification/deleteNotification',
//       {
//         id: notificationId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     )
//     .catch(produceViiisionApiError('Error getting scheduled push notifications'));
//   return response.data === 'Notification deleted successfully';
// }
