import Box from '@mui/material/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	buttonLinkChange,
	buttonLinkInvalid,
	linkDialogMenuChange,
	_buttonLink,
	_buttonLinkInvalid,
	_linkDialogMenu,
} from '../../../store/slices/editorSlice';
import { colors } from '../../../theme/palette';
import { FlatButton } from '../../Buttons/FlatButton';
import { ViiisionDialog } from '../../ViiisionDialog';
import SvgLink from '../../SvgComponents/SvgLink';
import { Mailto } from './LinkDialogComponents/Mailto';
import { PageTo } from './LinkDialogComponents/PageTo';
import { Phone, PhoneOption, PhoneScheme } from './LinkDialogComponents/Phone';
import { WebAddress } from './LinkDialogComponents/WebAddress';

export type LinkDialogMenuOption = 'page' | 'web' | 'file' | 'email' | 'phone' | 'none';
export type ButtonLink = {
	invalid: boolean;
	web: string;
	email: string;
	phone: PhoneOption;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			width: 500,
			maxHeight: 400,
		},
		closeIcon: {
			color: colors.pureWhite,
		},
		title: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexGrow: 1,
			color: colors.pureWhite,
		},
		titleContainer: {
			backgroundColor: colors.grayDark,
			paddingTop: 0,
			paddingBottom: 0,
			marginBottom: 0,
		},
		dialogActions: {
			maxWidth: 500,
			maxHeight: 52,
			backgroundColor: colors.grayLight5,
		},
		options: {
			width: 170,
			height: 300,
			borderRightStyle: 'solid',
			borderRightColor: colors.grayLight20,
			borderRightWidth: 2,
			marginTop: 15,
		},
		content: {
			width: 351,
			height: 300,
			marginTop: 20,
			marginLeft: 10,
			marginRight: 10,
		},
		box: {
			height: 300,
			display: 'flex',
			flexDirection: 'row',
		},
		dialogContent: {
			padding: 0,
		},
		radioGroup: {
			marginLeft: theme.spacing(2),
		},
		textField: {
			marginTop: 5,
			width: 311,
		},
		noLinkBox: {
			width: 120,
			height: 120,
			margin: 'auto',
			marginTop: 50,
			borderRadius: 60,
			textAlign: 'center',
			verticalAlign: 'middle',
			backgroundColor: colors.grayLight5,
		},
		noLinkContainer: {
			textAlign: 'center',
		},
		noLinkIcon: {
			paddingTop: 35,
		},
		noLinkTitle: {
			color: colors.grayLight,
		},
	})
);

export type LinkOption = {
	value: LinkDialogMenuOption;
	label: string;
};

export const LinkDialogOptions: LinkOption[] = [
	{
		value: 'none',
		label: 'None',
	},
	{
		value: 'page',
		label: 'Page',
	},
	{
		value: 'web',
		label: 'Web Address',
	},
	{
		value: 'phone',
		label: 'Phone',
	},
	{
		value: 'email',
		label: 'Email',
	},
];

export type LinkDialogProps = {
	open: boolean;
	options: LinkOption[];
	component: React.ReactNode;
	value: string;
	handleClose: () => void;
	onChange: (newValue: string) => void;
	onLinkDialogMenuChange: (selected: string) => void;
	setButtonLinkInvalid: (boolean) => void;
	selected: LinkDialogMenuOption;
	invalid: boolean;
	buttonLink: ButtonLink;
	setTemp: (temp: string) => void;
};

export const LinkDialog = (props: LinkDialogProps) => {
	const {
		open,
		handleClose,
		options,
		onLinkDialogMenuChange,
		component,
		selected,
		invalid,
		buttonLink,
		onChange,
		setButtonLinkInvalid,
		setTemp,
	} = {
		...props,
	};
	const classes = useStyles();

	const handleChange = (e) => {
		setTemp('');
		if (e.target.value === 'none') {
			setButtonLinkInvalid(false);
		} else {
			setButtonLinkInvalid(true);
		}
		onLinkDialogMenuChange(e.target.value);
	};

	return (
		<ViiisionDialog
			title="Add a Link"
			handleClose={handleClose}
			open={open}
			actions={
				<>
					<FlatButton onClick={handleClose}>Cancel</FlatButton>
					<FlatButton
						disabled={invalid}
						variant="contained"
						color="primary"
						type="submit"
						onClick={(e) => {
							const link =
								selected === 'phone'
									? buttonLink[selected].number
									: selected === 'web'
									? buttonLink[selected].split('#', 2).join('#') //removing extra #
									: selected === 'none'
									? ''
									: buttonLink[selected];
							setTemp(link);
							onChange(link);
							handleClose();
							setButtonLinkInvalid(true);
						}}
					>
						Save
					</FlatButton>
				</>
			}
		>
			<Box className={classes.box} overflow="hidden">
				<Box className={classes.options}>
					<RadioGroup className={classes.radioGroup}>
						{options.map((option) => {
							return (
								<FormControlLabel
									key={option.value}
									control={
										<Radio
											color="primary"
											checked={selected === option.value}
											onChange={handleChange}
											value={option.value}
										/>
									}
									label={<Typography variant="body2">{option.label}</Typography>}
									labelPlacement="end"
								/>
							);
						})}
					</RadioGroup>
				</Box>
				<Box className={classes.content}>{component}</Box>
			</Box>
		</ViiisionDialog>
	);
};

// This is named "container", which implies a separation of concerns from the dialog.
// I can't see it, not sure if this is necessary.
// TODO: Refactor this. Less code === less bugs.
const LinkDialogContainer = (
	props: Omit<
		LinkDialogProps,
		| 'selected'
		| 'component'
		| 'onLinkDialogMenuChange'
		| 'invalid'
		| 'buttonLink'
		| 'setButtonLinkInvalid'
		| 'setTemp'
	>
) => {
	const { value } = { ...props };
	const [temp, setTemp] = useState(value);

	useEffect(() => setTemp(value), [value]);
	const selected = useAppSelector(_linkDialogMenu);

	const buttonLink = useAppSelector(_buttonLink);
	const invalid = useAppSelector(_buttonLinkInvalid);
	let component: React.ReactNode;

	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			onLinkDialogMenuChange: linkDialogMenuChange,
			onButtonLinkChange: buttonLinkChange,
			setButtonLinkInvalid: buttonLinkInvalid,
		},
		dispatch
	);
	const classes = useStyles();
	switch (selected) {
		case 'web':
			component = (
				<WebAddress
					value={temp}
					setButtonLinkInvalid={actions.setButtonLinkInvalid}
					onLinkChange={(web: string) => {
						actions.onButtonLinkChange({ link: web, linkOption: 'web' });
					}}
				/>
			);
			break;
		case 'email':
			component = (
				<Mailto
					value={temp}
					setButtonLinkInvalid={actions.setButtonLinkInvalid}
					onLinkChange={(mailto: string) => {
						actions.onButtonLinkChange({ link: mailto, linkOption: 'email' });
					}}
				/>
			);
			break;
		case 'phone':
			component = (
				<Phone
					scheme={value?.includes('tel') ? 'tel' : 'sms'}
					value={temp}
					setButtonLinkInvalid={actions.setButtonLinkInvalid}
					onLinkChange={(number: string, scheme: PhoneScheme) => {
						actions.onButtonLinkChange({ link: number, linkOption: 'phone', phoneScheme: scheme });
					}}
				/>
			);
			break;
		case 'page':
			component = (
				<PageTo
					value={value?.includes('http') ? '' : value}
					setButtonLinkInvalid={actions.setButtonLinkInvalid}
					onLinkChange={(topicId: string) => {
						actions.onButtonLinkChange({ link: topicId, linkOption: 'page' });
					}}
				/>
			);
			break;
		case 'none':
			component = (
				<Box className={classes.noLinkContainer}>
					<Box className={classes.noLinkBox}>
						<Box className={classes.noLinkIcon}>
							<SvgLink />
						</Box>
					</Box>
					<Typography className={classes.noLinkTitle} variant="subtitle1">
						{'Nothing Linked yet!'}
					</Typography>
				</Box>
			);
	}

	const state = { component, selected, invalid, buttonLink };

	return <LinkDialog setTemp={setTemp} {...props} {...state} {...actions} />;
};

export default LinkDialogContainer;
