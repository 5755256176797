import Backdrop from '@material-ui/core/Backdrop';
import { Theme } from '@material-ui/core/styles/createTheme';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ReactNode } from 'react';
import { drawerWidth } from './AppEditor/ViiisionDrawer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		big: {
			[theme.breakpoints.up('sm')]: {
				left: drawerWidth,
			},
			zIndex: 3,
		},
		small: {
			position: 'absolute',
			zIndex: 2,
			borderRadius: 7,
		},
	})
);

type BlockingModalProps = {
	open: boolean;
	children: ReactNode;
	fullPage?: boolean;
};

export const BlockingModal = (props: BlockingModalProps) => {
	const { open, fullPage = false, children } = props;
	const classes = useStyles();

	// const LimitedBackdrop =

	return (
		<Backdrop open={open} className={fullPage ? classes.big : classes.small}>
			{children}
		</Backdrop>
	);
};
