import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useChangeRoute } from '../../hooks/useChangeRoute';
import { ViiisionAnalytics } from './Analytics/ViiisionAnalytics';
import { CardTitle } from './CardComponents/CardTitle';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formCard: {
			marginBottom: 20,
			width: 352,
			maxHeight: 671,
			backgroundColor: theme.palette.background.paper,
		},
		analyticsContent: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
	})
);

export type AnalyticsCardProps = {
	locationId: string;
};

export const AnalyticsCard = (props: AnalyticsCardProps) => {
	const classes = useStyles();
	const toAnalytics = useChangeRoute('analytics');

	return (
		<Card variant="outlined" className={classes.formCard}>
			<CardContent className={classes.analyticsContent}>
				<CardTitle
					title="Analytics"
					subTitle="Last 30 days"
					info={<span onClick={toAnalytics}>View All Analytics</span>}
				/>
				<ViiisionAnalytics />
			</CardContent>
		</Card>
	);
};
