import React, { useState } from 'react';
import { DISMISSED_LANGUAGE_DELETE } from '../../util/constants';
import { ViiisionMenu } from '../Dashboard/CardComponents/Menu/ViiisionMenu';
import { ViiisionMenuItem } from '../Dashboard/CardComponents/Menu/ViiisionMenuItem';
import { LanguageDeleteDialog } from './LanguageDeleteDialog';
import { PrimaryLanguageDeleteDialog } from './PrimaryLanguageDeleteDialog';

export type MultilanguageMenuProps = {
	primary: boolean;
	languageCode: string;
	supported: string[];
	setSupported: (supported: string[]) => void;
	removeLanguage: (languageCode: string) => void;
	changePrimary: (languageCode: string) => void;
};

export const MultilanguageMenu = (props: MultilanguageMenuProps) => {
	const { primary, languageCode, supported, setSupported, removeLanguage, changePrimary } = { ...props };
	const [anchor, setAnchor] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

	const handleChangePrimary = () => changePrimary(languageCode);
	const handleDelete = () => {
		setSupported(supported.filter((code) => code !== languageCode));
		removeLanguage(languageCode);
	};
	const closeDialog = () => setOpenDialog(false);

	return (
		<ViiisionMenu anchor={anchor} setAnchor={setAnchor}>
			{primary ? (
				<PrimaryLanguageDeleteDialog
					open={openDialog}
					current={languageCode}
					supported={supported}
					handleClose={closeDialog}
					handleDelete={handleDelete}
					handleChangePrimary={changePrimary}
				/>
			) : (
				<LanguageDeleteDialog open={openDialog} handleClose={closeDialog} handleDelete={handleDelete} />
			)}
			<ViiisionMenuItem
				setAnchor={setAnchor}
				text="Delete"
				handleClick={() => {
					!primary && localStorage.getItem(DISMISSED_LANGUAGE_DELETE) ? handleDelete() : setOpenDialog(true);
				}}
			/>
			{!primary && (
				<ViiisionMenuItem setAnchor={setAnchor} text="Make Primary" handleClick={handleChangePrimary} />
			)}
		</ViiisionMenu>
	);
};
