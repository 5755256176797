import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { Topic } from '../../../../store/models';
import { _activeLanguageCode } from '../../../../store/slices/editorSlice';
import { _topics } from '../../../../store/slices/topicSlice';
import { colors } from '../../../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menu: {
			backgroundColor: colors.pureWhite,
			borderStyle: 'solid',
			borderWidth: 0.5,
			borderColor: colors.grayLight20,
			borderRadius: 10,
			padding: 0,
			maxHeight: '60%',
			marginLeft: -11,
			width: 320,
			'& li': {
				fontSize: 12,
				paddingLeft: 8,
			},
			'& li:hover': {
				backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #49525D',
			},
			'& li.Mui-selected': {
				backgroundColor: colors.tealAccent20,
			},
		},
		select: {
			height: 38,
			width: 320,
			backgroundColor: colors.pureWhite,
			borderStyle: 'solid',
			borderWidth: 1,
			borderRadius: 10,
			borderColor: colors.grayLight,
			marginTop: 10,
			paddingLeft: 10,
			paddingRight: 10,
			paddingTop: 12,
			paddingBottom: 8,
			fontSize: 12,
			'&:hover': {
				borderColor: colors.blueAccent20,
			},
			'&.Mui-focused': {
				borderColor: colors.tealAccent,
				backgroundColor: colors.pureWhite,
			},
			'& div.MuiSelect-select:focus': {
				backgroundColor: colors.pureWhite,
			},
		},
	})
);

export type PageToProps = {
	value?: string;
	onLinkChange: (topicId: string) => any;
	setButtonLinkInvalid: (invalid: boolean) => void;
};

export const PageTo = (props: PageToProps) => {
	const { value, onLinkChange, setButtonLinkInvalid } = { ...props };
	const topics: Topic[] = Object.values(useAppSelector(_topics));
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const classes = useStyles();
	const [selected, setSelected] = useState(value ? value : topics.length > 0 ? topics[0].id : '');

	const handleChange = (e) => {
		onLinkChange(e.target.value);
		setButtonLinkInvalid(false);
		setSelected(e.target.value);
	};

	useEffect(() => {
		if (selected === '' || selected === null) {
			setSelected(topics[0].id);
		}
		onLinkChange(selected);
		setButtonLinkInvalid(false);
	}, [selected, setSelected, topics, setButtonLinkInvalid, onLinkChange]);

	return (
		<>
			<Typography variant="subtitle1">{'Page'}</Typography>
			{topics && (
				<Select
					fullWidth
					onChange={handleChange}
					onLoadedData={() => {
						if (selected === '' || selected === null) {
							setSelected(topics[0].id);
							setButtonLinkInvalid(false);
							onLinkChange(topics[0].id);
						}
						setButtonLinkInvalid(false);
					}}
					value={selected}
					MenuProps={{ classes: { paper: classes.menu } }}
					className={classes.select}
					disableUnderline
				>
					{/* generate a list of page names to choose from */}
					{topics.map((topic: Topic) => (
						<MenuItem key={topic.id} value={topic.id}>
							<Typography variant="caption">
								{topic.name.find((lang) => lang.language === activeLanguageCode).name ||
									topic.name[0].name}
							</Typography>
						</MenuItem>
					))}
				</Select>
			)}
		</>
	);
};
