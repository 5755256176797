import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ViewsByPayload } from '../../../../store/models';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chartContent: {
			display: 'flex',
			justifyContent: 'center',
		},
	})
);

export type ViewsByProps = {
	data: ViewsByPayload;
};

export const ViewsBy = (props: ViewsByProps) => {
	const classes = useStyles();
	const { data } = props;

	const chartOptions: Options = {
		chart: {
			type: 'column',
			height: 150,
			zoomType: 'xy',
			panning: {
				enabled: true,
				type: 'xy',
			},
			panKey: 'shift',
		},
		title: {
			text: '',
		},
		series: [
			{
				type: 'column',
				data: [data['app'], data['history'], data['link'], data['scan'], data['share']],
			},
		],
		xAxis: {
			categories: ['App', 'History', 'Link', 'Scan', 'Share'],
			labels: {
				enabled: true,
				useHTML: true,
				formatter: function () {
					return `${this.value}`;
				},
				style: {
					color: colors.grayDark,
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		yAxis: {
			title: { text: null },
			labels: {
				style: {
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		credits: {
			enabled: false, //removing watermark
		},
		tooltip: {
			useHTML: true,
			backgroundColor: colors.grayDark,
			borderWidth: 0,
			borderRadius: 4,
			shadow: false,
			style: {
				padding: '8px 12px',
				color: colors.pureWhite,
				fontSize: '16px',
				fontWeight: '700',
				whiteSpace: 'nowrap',
			},
			formatter: function () {
				return `${this.y}`;
			},
		},
		legend: { enabled: false },
		plotOptions: {
			series: {
				cursor: 'pointer',
				color: colors.navyAccent,
			},
		},
	};

	return (
		<ChartContent name={'VIEWS BY'} toolTipMsg={'The number of views based on how a user accessed your Viiision.'}>
			<Box className={classes.chartContent}>
				<HighchartsReact highcharts={Highcharts} options={chartOptions} />
			</Box>
		</ChartContent>
	);
};
