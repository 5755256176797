import { createStyles, makeStyles, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { topicEnableSharing } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { ViiisionTooltip } from '../ViiisionTooltip';
import { SvgShareDisabled } from '../SvgComponents/SvgShareDisabled';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: colors.grayLight,
			'&:hover': {
				color: colors.blueAccent,
			},
		},
		button: {
			marginLeft: 10,
			'&:hover': {
				backgroundColor: colors.blueAccent20,
			},
		},
	})
);

export type ShareButtonProps = {
	topicId: string;
	initialShareStatus: boolean;
	sharePage: (payload: { enableSharing: boolean; topicId: string }) => void;
	// visiblePage: (payload: { enableSharing: boolean; topicId: string }) => void;
};

export const ShareButton = (props: ShareButtonProps) => {
	const { topicId, initialShareStatus, sharePage } = { ...props };
	const classes = useStyles();

	const [shareEnabled, setShareEnabled] = useState(initialShareStatus);

	const handleClick = () => {
		setShareEnabled(!shareEnabled);
		// visiblePage({ enableSharing: !shareEnabled, topicId });
		sharePage({ enableSharing: !shareEnabled, topicId });
	};

	const message = shareEnabled ? 'Sharing Enabled' : 'Sharing Disabled';

	const shareIcon = shareEnabled ? (
		<ShareIcon fontSize="small" className={classes.icon} />
	) : (
		<SvgShareDisabled fontSize="small" className={classes.icon} />
	);

	return (
		<ViiisionTooltip message={message} placement="right">
			<IconButton className={classes.button} onClick={handleClick} size="small">
				{shareIcon}
			</IconButton>
		</ViiisionTooltip>
	);
};

const ShareButtonContainer = (props: Omit<ShareButtonProps, 'sharePage' | 'visiblePage'>) => {
	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			// visiblePage: visibleTopicOptions,
			sharePage: topicEnableSharing,
		},
		dispatch
	);

	return <ShareButton {...props} {...actions} />;
};

export default ShareButtonContainer;
