import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../models';

export interface OrganizationsMap {
	[id: string]: Organization;
}
export interface OrganizationState {
	organizations: OrganizationsMap;
	selectedOrgId: string;
	isLoading: boolean;
}

const initOrganizationState: OrganizationState = {
	organizations: {} as OrganizationsMap,
	selectedOrgId: null,
	isLoading: false,
};

//---------------------- Thunks ----------------------

// no thunks yet

export interface newOrgPayload {
	email: string;
	password: string;
	name: string;
	parentId?: string;
	parentMenuId?: string;
	type?: string;
}

//---------------------- Slice ----------------------

const organizationSlice = createSlice({
	name: 'organization',
	initialState: initOrganizationState,
	reducers: {
		updateOrganization: (state: OrganizationState, action: PayloadAction<Partial<Organization>>) => {
			//accepts a partial organization object
			// making this always modify the selected organization, CHANGE THIS IF WE START USING MULTI ORG
			state.organizations[state.selectedOrgId] = {
				...state.organizations[state.selectedOrgId],
				...action.payload,
			};
		},
		saveOrganization: (state: OrganizationState) => {
			// this reducer is used to save the organization to the database. The api call happens in the ViiisionMiddleware in store.ts, when this action is intercepted
			// this was created for use with the onBlur event when editing the org name in the templateViewer
		},
		createNewOrganization: (state: OrganizationState, action: PayloadAction<newOrgPayload>) => {
			// this reducer is used to create a new organization. The api call happens in the ViiisionMiddleware in store.ts, when this action is intercepted
			// the payload will be modified to contain the newly created org
			// this was created for use in the sign-up flow
			let id = action.payload['id'];
			state.organizations[id] = action.payload;
			state.selectedOrgId = id;
		},
		fetchOrganization: (state: OrganizationState, action: PayloadAction<Organization>) => {
			// this reducer is used to fetch an organization. The api call happens in the ViiisionMiddleware in store.ts, when this action is intercepted
			// the payload will be modified to contain the fetched org and the store will be updated with the org
			let id = action.payload['id'];
			state.organizations[id] = action.payload;
			state.selectedOrgId = id;
		},
	},
	extraReducers: (builder) => {
		// builder.addCase(fetchOrganization.fulfilled, (state, action) => {
		//   state.isLoading = false;
		//   let id = action.payload['_id'];
		//   state.organizations[id] = action.payload;
		// });
		// builder.addCase(fetchOrganization.pending, (state) => {
		//   state.isLoading = true;
		// });
		// builder.addCase(fetchOrganization.rejected, (state) => {
		//   state.isLoading = false;
		// });
	},
});

//---------------------- Exports ----------------------

// Export reducer
export default organizationSlice.reducer;

// Export actions
export const {
	updateOrganization,
	saveOrganization,
	createNewOrganization,
	fetchOrganization,
} = organizationSlice.actions;

// Export selectors
export const _organizations = (state) => state.organization.organizations;
export const _selectedOrgId = (state) => state.organization.selectedOrgId;
export const _organization = (state) => state.organization.organizations[state.organization.selectedOrgId];
export const _selectedOrganization = (state) => state.organization.organizations[state.organization.selectedOrgId];
export const _isLoading = (state) => state.organization.isLoading;
