import Box from '@mui/material/Box';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { colors } from '../../../theme/palette';
import { ViiisionTooltip } from '../../ViiisionTooltip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: colors.grayDark,
			display: 'grid',
			fontSize: 20,
		},
		subTitle: {
			fontSize: 14,
			color: colors.grayLight,
			justifyContent: 'center',
		},
		titleBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		icon: {
			color: colors.grayLight,
			'&:hover': {
				color: colors.tealAccent,
			},
		},
		button: {
			'&:hover': {
				backgroundColor: colors.tealAccent20,
			},
		},
		menu: {
			backgroundColor: colors.pureWhite,
			filter: 'drop-shadow(0px 2px 8px rgba(24, 27, 32, 0.2))',
			borderRadius: 5,
		},
		info: {
			color: colors.blueAccent,
			fontSize: 14,
			alignSelf: 'flex-start',
			marginTop: 5,
			cursor: 'pointer',
		},
	})
);

export type CardTitleProps = {
	title: string;
	info?: string | any;
	menu?: React.FunctionComponent;
	msg?: string;
	subTitle?: string;
};

export const CardTitle = (props: CardTitleProps) => {
	const { title, info, menu: Menu, msg, subTitle = '' } = { ...props };
	const classes = useStyles();

	return (
		<Box className={classes.titleBox}>
			<Typography className={classes.title} variant="h5">
				{title}
				<div className={classes.subTitle}>{subTitle}</div>
			</Typography>
			{info && <Typography className={classes.info}>{info}</Typography>}
			{Menu && (
				<ViiisionTooltip placement="left" message={msg}>
					<div>
						<Menu />
					</div>
				</ViiisionTooltip>
			)}
		</Box>
	);
};
