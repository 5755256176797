import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIconComponent } from '@material-ui/icons';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import InsertDriveFile from '@material-ui/icons/InsertDriveFileOutlined';
import React, { useEffect, useState } from 'react';
import { colors } from '../../theme/palette';
import { ViiisionTooltip } from '../ViiisionTooltip';
import { QrIcon } from '../SvgComponents/SvgQr';
import { MultiLingIcon } from '../SvgComponents/SvgMultiLing';
import { ViiisionDrawer } from './ViiisionDrawer';
import MultilanguageDrawer from '../ML/MLDrawer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _plan, changeLeftDrawerMenu } from '../../store/slices/editorSlice';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageAdd: {
			backgroundColor: colors.grayLight20,
			justifyContent: 'space-between',
		},
		item: {
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.default,
			},
		},
		leftNavItem: {
			paddingTop: 16,
			paddingBottom: 16,
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.default,
				borderRightStyle: 'solid',
				borderRightWidth: '3px',
				borderRightColor: theme.palette.primary.main,
			},
		},
		leftNav: {
			paddingTop: 0,
		},
		iconSelectedColor: {
			color: theme.palette.primary.main,
		},
		iconNotSelectedColor: {
			color: colors.grayDark,
		},
		tourWrapper: {
			height: '100%',
		},
	})
);

export type MenuSelectOption = {
	name: string;
	message: string;
	icon: SvgIconComponent;
};

const defaultOptions: MenuSelectOption[] = [
	{
		name: 'page',
		message: 'Pages',
		icon: InsertDriveFile,
	},
	{
		name: 'element',
		message: 'Elements',
		icon: AddCircle,
	},
	{
		name: 'qr',
		message: 'QR Code',
		icon: QrIcon,
	},
	{
		name: 'multilingual',
		message: 'Multilingual',
		icon: MultiLingIcon,
	},
];

export type MenuSelectProps = {
	selected: string;
	onLeftMenuClick: (menuOption: string) => void;
	options?: MenuSelectOption[];
};

const defaultProps: Partial<MenuSelectProps> = {
	options: defaultOptions,
};

export const MenuSelect = (props: MenuSelectProps) => {
	const { selected, onLeftMenuClick, options } = { ...defaultProps, ...props };
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const plan = useAppSelector(_plan);
	const [open, setOpen] = useState(false);
	const [conditionalOptions, setConditionalOptions] = useState(options);
	const setDrawerOption = bindActionCreators(changeLeftDrawerMenu, dispatch);

	// This is where we modify the drawer options
	// If the plan is Lite, remove the page option from the left menu and set default display to element content

	useEffect(() => {
		if (plan?.name === 'Lite') {
			let filteredOptions = options.filter((option) => option.name !== 'page');
			setConditionalOptions(filteredOptions);
			setDrawerOption('element');
		}
		// eslint-disable-next-line
	}, [plan]);

	return (
		<ViiisionDrawer anchor="left" isExpanded={false}>
			<MultilanguageDrawer open={open} setOpen={setOpen} />
			<div data-tour="left-tab" className={classes.tourWrapper}>
				<List classes={{ root: classes.leftNav }}>
					{conditionalOptions.map((option, index) => (
						<ViiisionTooltip key={option.name} message={option.message} placement="right">
							<ListItem
								key={index}
								button
								onClick={() => {
									if (option.name === 'multilingual') setOpen(true);
									else onLeftMenuClick(option.name);
								}}
								classes={{ root: classes.leftNavItem }}
								selected={selected === option.name}
							>
								<ListItemIcon
									className={
										selected === option.name
											? classes.iconSelectedColor
											: classes.iconNotSelectedColor
									}
								>
									<option.icon />
								</ListItemIcon>
							</ListItem>
						</ViiisionTooltip>
					))}
				</List>
			</div>
		</ViiisionDrawer>
	);
};
